import { useCallback, useState } from "react";
import { ListProduct, ListProductSoho } from "../../pages/home/listProduct";
import { motion } from "framer-motion";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ModalTV from "../modaltv/modaltv";
import { bottomUp } from "../animate/animate";

const PaketContent = ({ page }: { page: string }): JSX.Element => {
  const [area, setArea] = useState("Depok");
  const [typeProduct, setTypeProduct] = useState("Internet Only");
  const [paketType, setPaketType] = useState("home");

  const handlerArea = useCallback(
    (activearea: string) => {
      setArea(activearea);
    },
    [area]
  );

  const handlerTypeProduct = useCallback(
    (activeTypeProduct: string) => {
      setTypeProduct(activeTypeProduct);
    },
    [typeProduct]
  );

  const handleChangePaket = useCallback(
    (paket: string) => {
      setPaketType(paket);
      setArea("Depok");
    },
    [paketType]
  );

  return (
    <>
      <section className="bg-fourth">
        <div className="container py-5 t-black">
          {/* <div className="d-flex justify-content-center">
            <h5 className="t-red fw-semibold">PAKET</h5>
          </div> */}

          {page === "paket" && (
            <div className="d-flex justify-content-evenly mb-3">
              <div
                className={`${
                  paketType === "home" ? "button-85" : "button-85-disabled"
                }`}
                onClick={() => handleChangePaket("home")}
              >
                Dukodu HOME
              </div>
              <div
                className={`${
                  paketType === "soho" ? "button-85" : "button-85-disabled"
                }`}
                onClick={() => handleChangePaket("soho")}
              >
                Dukodu SOHO
              </div>
            </div>
          )}

          {/* {page === "paket" && (
            <div className="t-white">jfhsdkjflhsdkjfahsadkjfhsdkjfhdk</div>
          )} */}

          <div className="d-flex justify-content-center">
            <h3 className="fw-bold">
              {page === "home" ? "PAKET TERBAIK KAMI" : "PAKET KAMI"}
            </h3>
          </div>

          {/* wilayah */}
          <div className="mb-3">
            <ul className="nav nav-tabs d-flex justify-content-center">
              {paketType === "home" &&
                ListProduct.map((item: any) => (
                  <li className="nav-item" key={item.area}>
                    <div
                      className={`nav-link my-nav-link ${
                        area === item.area ? "active" : ""
                      }`}
                      onClick={() => handlerArea(item.area)}
                    >
                      {item.area}
                    </div>
                  </li>
                ))}

              {paketType === "soho" &&
                ListProductSoho.map((item: any) => (
                  <li className="nav-item" key={item.area}>
                    <div
                      className={`nav-link my-nav-link ${
                        area === item.area ? "active" : ""
                      }`}
                      onClick={() => handlerArea(item.area)}
                    >
                      {item.area}
                    </div>
                  </li>
                ))}
            </ul>
          </div>

          {/* service */}
          <div className="mb-3">
            <ul className="nav text-center d-flex justify-content-center">
              {paketType === "home" &&
                ListProduct.map((item: any) =>
                  item.area === area
                    ? item.type_product.map((subitem: any) => (
                        <li
                          className="nav-item my-nav-product"
                          key={subitem.name}
                        >
                          <div
                            className={`nav-link ${
                              typeProduct === subitem.name ? "active" : ""
                            }`}
                            onClick={() => handlerTypeProduct(subitem.name)}
                          >
                            {subitem.name}
                          </div>
                        </li>
                      ))
                    : ""
                )}

              {paketType === "soho" &&
                ListProductSoho.map((item: any) =>
                  item.area === area
                    ? item.type_product.map((subitem: any) => (
                        <li
                          className="nav-item my-nav-product"
                          key={subitem.name}
                        >
                          <div
                            className={`nav-link ${
                              typeProduct === subitem.name ? "active" : ""
                            }`}
                            onClick={() => handlerTypeProduct(subitem.name)}
                          >
                            {subitem.name}
                          </div>
                        </li>
                      ))
                    : ""
                )}
            </ul>
          </div>

          <div className="row mt-4 mb-3">
            {paketType === "home" &&
              ListProduct.map((item: any) =>
                item.area === area
                  ? item.type_product.map((subitem: any) =>
                      subitem.name === typeProduct
                        ? subitem.desc_product?.map((subsubitem: any) => (
                            <div
                              className="col-lg-6 col-xl-3"
                              key={subsubitem.name}
                            >
                              <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.6 }}
                                variants={bottomUp}
                              >
                                <div className="card product-item">
                                  <div className="product-header">
                                    <h4 className="fw-bold">
                                      {subsubitem.name}
                                    </h4>
                                    <LazyLoadImage
                                      src={subsubitem.image}
                                      alt="dukodu"
                                      width={140}
                                      height={140}
                                    />

                                    <hr />
                                    <div className="product-header-content mt-2">
                                      <h4 className="fw-semibold">
                                        {subsubitem.price}
                                      </h4>
                                      <div className="fs-10">
                                        <span>
                                          *Harga Belum Termasuk PPN 11%
                                        </span>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="product-content fs-7 text-start d-flex justify-content-center">
                                      <ul>
                                        {subsubitem.feature?.map(
                                          (feature: any) => (
                                            <li key={feature.name}>
                                              {feature.name}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                    <hr />
                                  </div>
                                  <div>Add Ons yang didapatkan :</div>
                                  <div className="product-content fs-7 text-start d-flex justify-content-center">
                                    {subsubitem.addons.length === 0 ? (
                                      "-"
                                    ) : (
                                      <ul>
                                        {subsubitem.addons?.map(
                                          (addons: any) => (
                                            <li key={addons.name}>
                                              {addons.name}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                  <a
                                    href="https://wa.me/6281215550572?text=Halo%20Dukodu,%20saya%20mau%20berlangganan%20internet%20super%20cepat"
                                    className="theme-btn text-center"
                                    target={"_blank"}
                                  >
                                    Berlangganan
                                  </a>
                                </div>
                              </motion.div>
                            </div>
                          ))
                        : ""
                    )
                  : ""
              )}

            {paketType === "soho" &&
              ListProductSoho.map((item: any) =>
                item.area === area
                  ? item.type_product.map((subitem: any) =>
                      subitem.name === typeProduct
                        ? subitem.desc_product?.map((subsubitem: any) => (
                            <div
                              className="col-lg-6 col-xl-3"
                              key={subsubitem.name}
                            >
                              <motion.div
                                initial="offscreen"
                                whileInView="onscreen"
                                viewport={{ once: true, amount: 0.6 }}
                                variants={bottomUp}
                              >
                                <div className="product-item">
                                  <div className="product-header">
                                    <h4 className="fw-bold">
                                      {subsubitem.name}
                                    </h4>
                                    <LazyLoadImage
                                      src={subsubitem.image}
                                      alt="dukodu"
                                      width={140}
                                      height={140}
                                    />

                                    <hr />
                                    <div className="product-header-content mt-2">
                                      <h4 className="fw-semibold">
                                        {subsubitem.price}
                                      </h4>
                                      <div className="fs-10">
                                        <span>
                                          *Harga Belum Termasuk PPN 11%
                                        </span>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="product-content fs-7 text-start d-flex justify-content-center">
                                      <ul>
                                        {subsubitem.feature?.map(
                                          (feature: any) => (
                                            <li key={feature.name}>
                                              {feature.name}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    </div>
                                    <hr />
                                  </div>
                                  <div>Add Ons yang didapatkan :</div>
                                  <div className="product-content fs-7 text-start d-flex justify-content-center">
                                    {subsubitem.addons.length === 0 ? (
                                      "-"
                                    ) : (
                                      <ul>
                                        {subsubitem.addons?.map(
                                          (addons: any) => (
                                            <li key={addons.name}>
                                              {addons.name}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    )}
                                  </div>
                                  <a
                                    href="https://wa.me/6281215550572?text=Halo%20Dukodu,%20saya%20mau%20berlangganan%20internet%20super%20cepat"
                                    className="theme-btn text-center"
                                    target={"_blank"}
                                  >
                                    Berlangganan
                                  </a>
                                </div>
                              </motion.div>
                            </div>
                          ))
                        : ""
                    )
                  : ""
              )}
          </div>

          {/* modal tv */}
          <ModalTV />
        </div>
      </section>
    </>
  );
};

export default PaketContent;
