import { Helmet } from "react-helmet";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

const HelpCenter = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dukodu - Cek Tagihan</title>
      </Helmet>

      <Header page="cek-tagihan" />

      <div className="container mt-3">
        <div className="py-3">
          <div className="text-center">
            <div className="fs-32 fw-bold t-red">
              Selamat Datang di Pusat Bantuan Dukodu.id
            </div>
            <div>Periksa tagihan anda dengan nomor handphone.</div>
          </div>
        </div>
      </div>

      <div className="container">
        <div>1. Untuk Melihat Paket yang tersedia</div>
        <div>2. Tanya customer service</div>
        <div>3. Cek tagihan</div>
      </div>

      <Footer />
    </>
  );
};

export default HelpCenter;
