import logo from "../../assets/img/logo.webp";
import "./header.css";
import mail from "../../assets/img/mail.png";
import phone from "../../assets/img/phone.png";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import fb from "../../assets/img/facebook.svg";
import ig from "../../assets/img/instagram.svg";
import linkin from "../../assets/img/linkedin.svg";
import twitter from "../../assets/img/twitter.svg";

type HeaderProps = {
  page: string;
};

declare global {
  interface Window {
    qchatInitialize: (config: { id: string; code: string }) => void;
  }
}

const Header = ({ page }: HeaderProps): JSX.Element => {
  const [offset, setOffset] = useState(0);
  const [activeNav, setActiveNav] = useState(page);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header>
      {/* <div id="google_translate_element"></div> */}
      <div className="bg-base web-info">
        <div className="container t-white py-2">
          <div className="d-flex justify-content-between">
            <div className="d-flex fs-7">
              <div className="email me-5">
                <span>
                  <img src={mail} alt="mail" width="30" height="30" />
                </span>{" "}
                cs@dukodu.id
              </div>
              <div className="phone">
                <span>
                  <img src={phone} alt="phone" width="30" height="30" />
                </span>{" "}
                +62 811-5634-634
              </div>
            </div>
            <div className="d-flex">
              <a href="https://www.facebook.com/dukoduhome" target="_blank">
                <img
                  src={fb}
                  alt="dukodu"
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
              <a href="https://twitter/dukodu.id" target="_blank">
                <img
                  src={twitter}
                  alt="dukodu"
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
              <a href="https://www.instagram.com/dukodu.id/" target="_blank">
                <img
                  src={ig}
                  alt="dukodu"
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/dukodu/?originalSubdomain=id"
                target="_blank"
              >
                <img
                  src={linkin}
                  alt="dukodu"
                  style={{ width: "30px", height: "30px" }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <nav
        className={`navbar my-navbar navbar-expand-lg bg-base ${
          offset === 0 ? "" : "fixed-top"
        }`}
      >
        <div className="container">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="/">
            <img src={logo} alt="dukodu" width="200" height="70" />
          </a>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarTogglerDemo03"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 fw-semibold">
              <li className="nav-item" key={1}>
                <Link
                  className={`nav-link t-black ${
                    activeNav === "home" ? "active box px-2" : ""
                  }`}
                  to={"/"}
                >
                  BERANDA
                </Link>
              </li>

              <li className="nav-item" key={3}>
                <Link
                  className={`nav-link t-black ${
                    activeNav === "feature" ? "active box px-2" : ""
                  }`}
                  to={"/feature"}
                >
                  FITUR
                </Link>
              </li>
              <li className="nav-item" key={4}>
                <Link
                  className={`nav-link t-black ${
                    activeNav === "paket" ? "active box px-2" : ""
                  }`}
                  to={"/paket"}
                >
                  PAKET
                </Link>
              </li>
              <li className="nav-item" key={5}>
                <Link
                  className={`nav-link t-black ${
                    activeNav === "cek-tagihan" ? "active box px-2" : ""
                  }`}
                  to={"/cek-tagihan"}
                >
                  CEK TAGIHAN
                </Link>
              </li>
              {/* <li className="nav-item" key={4}>
                <Link
                  className={`nav-link t-black ${
                    activeNav === "blog" ? "active box px-2" : ""
                  }`}
                  to={"/blog"}
                >
                  BLOG
                </Link>
              </li> */}
              <li className="nav-item" key={2}>
                <Link
                  className={`nav-link t-black ${
                    activeNav === "faq" ? "active box px-2" : ""
                  }`}
                  to={"/faq"}
                >
                  FAQ
                </Link>
              </li>
              <li className="nav-item me-4" key={6}>
                <Link
                  className={`nav-link t-black ${
                    activeNav === "contact" ? "active box px-2" : ""
                  }`}
                  to={"/contact"}
                >
                  KONTAK
                </Link>
              </li>

              <li className="vr"></li>

              <li className="nav-item me-4" key={7}>
                <a
                  className={`nav-link t-black`}
                  href="https://my.dukodu.id/"
                  target="_blank"
                >
                  <div>DUKODU HOTSPOT</div>
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: "lighter",
                      position: "absolute",
                      bottom: "-3px",
                    }}
                  >
                    by <img src={logo} alt="dukodu" width={47} height={16} />
                  </span>
                </a>
              </li>
              {/* <li className="nav-item" key={6}>
                <Link className="nav-link t-black" to={"/signup"}>
                  DAFTAR
                </Link>
              </li>
              <li className="nav-item" key={7}>
                <Link
                  className="nav-link t-black"
                  to={"https://dukodu.id/login"}
                >
                  MASUK
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
