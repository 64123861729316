import { Helmet } from "react-helmet";
import FeatureContent from "../../components/featureContent/featureContent";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ThumbnailPage from "../../components/thumbnailPage/thumbnailPage";

const Feature = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dukodu - Feature</title>
      </Helmet>

      <Header page="feature" />

      {/* thumbnail */}
      <ThumbnailPage title="Fitur Kami" />

      {/* fitur */}
      <FeatureContent />

      <Footer />
    </>
  );
};

export default Feature;
