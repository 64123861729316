import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { useEffect } from "react";
import Confetti from "react-confetti";
import { useWindowSize } from "@react-hook/window-size";
import { Helmet } from "react-helmet";

const SuccessUpgrade = (): JSX.Element => {
  const [width, height] = useWindowSize();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const tokenParam = queryParams.get("t");

  useEffect(() => {
    if (!tokenParam) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Upgrade Berhasil</title>
      </Helmet>

      <Header page="success-upgrade" />

      {/* disini nanti berhasil */}
      <Confetti width={width} height={height} numberOfPieces={300} />
      <div className="my-5">
        <div className="container py-5">
          <h1
            className="text-center"
            style={{ fontSize: "4em", fontWeight: "900", color: "#df0303" }}
          >
            Upgrade Paket Berhasil
          </h1>

          <div className="mt-3">
            <h4 className="text-center">Terima Kasih!</h4>
          </div>

          <div className="mt-3">
            <div className="text-center">
              Terima Kasih sudah memilih paket Promo kami dan sudah menjadi
              pelanggan setia DUKODU.
              <br />
              <span className="fw-bold">
                Silahkan restart modem untuk mendapatkan paket terbaru.
              </span>
              <br />
              PROMO lainnya di website{" "}
              <a href="https://dukodu.id">https://dukodu.id</a> atau hubungi
              kami di{" "}
              <a href="https://wa.me/628115634634">
                https://wa.me/628115634634
              </a>{" "}
              dan email ke cs@dukodu.id apabila Bapak/Ibu membutuhkan informasi
              maupun bantuan lebih lanjut.
            </div>
          </div>

          <div className="mt-3 d-flex justify-content-center">
            <div className="theme-btn" onClick={() => navigate("/")}>
              {"Kembali ke beranda"}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SuccessUpgrade;
