import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FaqState } from "../utils/interfaces";

const initialState: FaqState = {
  loading: false,
  data: {},
};

export const getFaqData = createAsyncThunk("get/getFaqData", async () => {
  try {
    const response = await axios.get(`/api/data/faq?_=${Math.random()}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFaqData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFaqData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getFaqData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default faqSlice.reducer;
