import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";

const ErrorPayment = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pembayaran Bermasalah</title>
      </Helmet>

      <Header page="finish-invoice" />

      {/* disini nanti berhasil */}
      <div className="my-5">
        <div className="container py-5">
          <h1
            className="text-center"
            style={{ fontSize: "4em", fontWeight: "900", color: "#df0303" }}
          >
            Pembayaran Bermasalah
          </h1>
          {/* 
          <div className="mt-3">
            <h4 className="text-center">Terima Kasih!</h4>
          </div> */}

          <div className="mt-3">
            <div className="text-center">
              Silahkan hubungi kami di{" "}
              <a href="https://wa.me/628115634634">
                https://wa.me/628115634634
              </a>{" "}
              dan email ke cs@dukodu.id apabila Bapak/Ibu membutuhkan informasi
              maupun bantuan lebih lanjut.
            </div>
          </div>

          <div className="mt-3 d-flex justify-content-center">
            <div className="theme-btn" onClick={() => navigate("/")}>
              {"Kembali ke beranda"}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default ErrorPayment;
