import { Helmet } from "react-helmet";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { Form, InputGroup, Toast } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { useCallback, useEffect, useState } from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { UpgradeData } from "../../utils/types";
import { useAppDispatch } from "../../app/hook";
import {
  getPromoPaketData,
  postUpgradeData,
} from "../../features/upgradeSlice";
import { useLocation, useNavigate } from "react-router-dom";

const initialDataForm: UpgradeData = {
  name: "",
  nohp: "",
  email: "",
  paketExist: {
    id: "",
    name: "",
    price: "",
  },
  paketUp: {
    label: "",
    value: "",
  },
};

const Promo = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const tokenParam = queryParams.get("t");
  const nohp = queryParams.get("p");
  const requestTime = queryParams.get("rt");

  const [formUpgrade, setFormUpgrade] = useState<UpgradeData>(initialDataForm);
  const [responseUpgrade, setResponseUpgrade] = useState("");
  const [toastFormUpgrade, setToastFormUpgrade] = useState<boolean>(false);
  const [errorFormUpgrade, setErrFormUpgrade] = useState({
    name: "",
    nohp: "",
    email: "",
    paketExist: "",
    paketUp: "",
  });
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [promoPaket, setPromoPaket] = useState([]);
  const [disableUpgrade, setDisableUpgrade] = useState(false);

  const onVerify = useCallback((token: any) => {
    setToken(token);
  }, []);

  const filterPromoPaket = (inputValue: string) => {
    return promoPaket?.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadOptionsPromoPaket = (
    inputValue: string,
    callback: (options: any) => void
  ) => {
    callback(filterPromoPaket(inputValue));
  };

  const submitFormUpgrade = (e: any) => {
    e.preventDefault();

    let payload = new FormData();

    payload.append("t", tokenParam ? tokenParam : "");
    payload.append("p", formUpgrade.nohp);
    payload.append("epkid", formUpgrade.paketExist.id);
    payload.append("pkid", formUpgrade.paketUp.value);
    payload.append("tkndkd", token);

    if (!disableUpgrade) {
      dispatch(postUpgradeData(payload))
        .unwrap()
        .then((data) => {
          setResponseUpgrade(data.msg);
          setToastFormUpgrade(true);
          if (data.status) {
            setTimeout(() => {
              navigate(`/verification?p=${nohp}&t=${tokenParam}`);
            }, 1500);
          }
        })
        .catch((err) => {
          setResponseUpgrade(err.msg);
          setToastFormUpgrade(true);
        });
    }
  };

  useEffect(() => {
    if (!nohp || !tokenParam || !requestTime) {
      navigate("/");
    }
    const intervalId = setInterval(() => {
      // Call your function or perform the desired actions here
      setRefreshReCaptcha((r) => !r);
    }, 2 * 60 * 1000); // 2 minutes in milliseconds

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component is unmounted
    };
  }, []);

  useEffect(() => {
    if (nohp && tokenParam && requestTime !== null) {
      dispatch(getPromoPaketData({ nohp, tokenParam, requestTime }))
        .unwrap()
        .then((res) => {
          setPromoPaket(res?.data?.promo);
          setFormUpgrade({
            ...formUpgrade,
            name: res.data.exist.customer_name,
            nohp: res.data.exist.customer_phone,
            email: res.data.exist.customer_email,
            paketExist: {
              id: res.data.exist.package_id,
              name: res.data.exist.package_name,
              price: res.data.exist.package_price,
            },
          });
        })
        .catch((err) => console.log(err));
    }
  }, [nohp, tokenParam, requestTime]);

  useEffect(() => {
    if (formUpgrade.paketUp.value) {
      setDisableUpgrade(false);
    } else {
      setDisableUpgrade(true);
    }
  }, [formUpgrade.paketUp.value]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dukodu - Promo</title>
      </Helmet>

      <Header page="promo" />

      <div className="container mt-3">
        <div className="py-3">
          <div className="text-center">
            <div className="fs-32 fw-bold t-red">Form Upgrade Paket</div>
          </div>
        </div>

        <Form>
          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Nama
                </label>
                <InputGroup hasValidation>
                  <Form.Control
                    as={"input"}
                    type="text"
                    value={formUpgrade.name}
                    minLength={1}
                    maxLength={120}
                    required
                    name="name"
                    id="name"
                    disabled
                  />
                </InputGroup>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div className="mb-3">
                <label htmlFor="hp" className="form-label">
                  Nomor Handphone
                </label>
                <InputGroup hasValidation>
                  <Form.Control
                    as={"input"}
                    type="text"
                    value={formUpgrade.nohp}
                    minLength={1}
                    maxLength={120}
                    required
                    name="hp"
                    id="hp"
                    disabled
                  />
                </InputGroup>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <InputGroup hasValidation>
                  <Form.Control
                    as={"input"}
                    type="text"
                    value={formUpgrade.email}
                    minLength={1}
                    maxLength={120}
                    required
                    name="email"
                    id="email"
                    disabled
                  />
                </InputGroup>
              </div>
            </div>
            <div className="col-md-6">
              {" "}
              <div className="mb-3">
                <label htmlFor="paket-exist" className="form-label">
                  Paket Sekarang
                </label>
                <InputGroup hasValidation>
                  <Form.Control
                    as={"input"}
                    type="text"
                    value={
                      formUpgrade.paketExist.name +
                      " - " +
                      formUpgrade.paketExist.price
                    }
                    minLength={1}
                    maxLength={120}
                    required
                    name="paket-exist"
                    id="paket-exist"
                    disabled
                  />
                </InputGroup>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <label htmlFor="paketupgrade" className="form-label">
                  Pilih Paket
                </label>

                <AsyncSelect
                  cacheOptions
                  name="paket-upgrade"
                  inputId="paketupgrade"
                  // value={
                  //   formUpgrade?.paketUp?.value +
                  //   formUpgrade?.paketUp?.label_price
                  // }
                  placeholder="Pilih paket..."
                  loadOptions={loadOptionsPromoPaket}
                  defaultOptions={promoPaket}
                  isClearable
                  getOptionLabel={(option: any) => option.label}
                  getOptionValue={(option: any) => option.value}
                  onChange={(e: any) => {
                    setFormUpgrade({
                      ...formUpgrade,
                      paketUp: {
                        value: e?.value,
                        label: e?.label,
                      },
                    });
                  }}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errorFormUpgrade.paketUp}
                </Form.Control.Feedback>
              </div>
            </div>
          </div>
        </Form>

        <div className="py-3">
          <div className="d-flex justify-content-start">
            <button
              className={disableUpgrade ? `theme-btn-disabled` : `theme-btn`}
              onClick={(e) => {
                submitFormUpgrade(e);
              }}
              disabled={disableUpgrade}
            >
              {`Lanjutkan`}
            </button>
          </div>
        </div>

        <GoogleReCaptchaProvider reCaptchaKey="6Ldxxw4bAAAAAHVBc271fWbPo5tLPiq1hlslR17a">
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider>
      </div>

      <Footer />

      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <Toast
          onClose={() => setToastFormUpgrade(false)}
          show={toastFormUpgrade}
          delay={1500}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">DUKODU</strong>
          </Toast.Header>
          <Toast.Body>{responseUpgrade}</Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default Promo;
