import { Helmet } from "react-helmet";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import PaketContent from "../../components/paketContent/paketContent";

const Paket = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dukodu - Paket</title>
      </Helmet>

      <Header page="paket" />

      {/* thumbnail */}
      {/* <ThumbnailPage title="Paket" /> */}

      {/* paket */}
      <PaketContent page="paket" />

      <Footer />
    </>
  );
};

export default Paket;
