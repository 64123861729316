import { FaqData } from "../../utils/types/faqData";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { getFaqData } from "../../features/faqSlice";

const Faq = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state) => state.faq);

  const [faqData, setFaqData] = useState<FaqData[]>([]);

  useEffect(() => {
    dispatch(getFaqData())
      .unwrap()
      .then((res) => setFaqData(res.data))
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      {!loading ? (
        <div className="container py-5">
          <h4 className="fw-semibold mb-3">Pertanyaan Yang Sering Diajukan</h4>
          <div className="accordion" id="accordionExample">
            {faqData.map((item: FaqData) => (
              <div className="accordion-item" key={item.id}>
                <h2 className="accordion-header" id={`heading${item.id}`}>
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${item.id}`}
                    aria-expanded="true"
                    aria-controls={`collapse${item.id}`}
                  >
                    {item.question}
                  </button>
                </h2>
                <div
                  id={`collapse${item.id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading${item.id}`}
                  data-bs-parent="#accordionExample"
                >
                  <div
                    className="accordion-body"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                  ></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Faq;
