import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ServiceState } from "../utils/interfaces";

const initialState: ServiceState = {
  loading: false,
  data: {},
  error: {},
};

export const getServiceData = createAsyncThunk(
  "get/getServiceData",
  async () => {
    try {
      const response = await axios.get(`/api/data/service?_=${Math.random()}`);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServiceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getServiceData.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getServiceData.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default serviceSlice.reducer;
