const TVChannel = [
  {
    name: "Bioskop Indonesia",
  },
  {
    name: "Insert",
  },
  {
    name: "CCTV 4",
  },
  {
    name: "Dunia Lain",
  },
  {
    name: "Channel Seru! HD",
  },
  {
    name: "TV 5 Monde",
  },
  {
    name: "ABC Australia",
  },
  {
    name: "Eat N Go",
  },
  {
    name: "Dunia Anak",
  },
  {
    name: "ID Cyclist",
  },
  {
    name: "Golf+ HD",
  },
  {
    name: "Historical Sports",
  },
  {
    name: "Al Quran Al Kareem",
  },
  {
    name: "tv Mu",
  },
  {
    name: "TV 9",
  },
  {
    name: "Pijar TV",
  },
  {
    name: "Khazanah",
  },
  {
    name: "DAAI TV",
  },
  {
    name: "Musik Indonesia HD",
  },
  {
    name: "Nusantara",
  },
  {
    name: "Lingua Channel HD",
  },
  {
    name: "Channel NewsAsia",
  },
  {
    name: "Wion",
  },
  {
    name: "Al-Jazeera",
  },
  {
    name: "Euronews",
  },
  {
    name: "Russia Today",
  },
  {
    name: "Arirang",
  },
  {
    name: "TransTV HD",
  },
  {
    name: "Trans7 HD",
  },
  {
    name: "Metro TV",
  },
  {
    name: "CNN Indonesia HD",
  },
  {
    name: "tvOne",
  },
  {
    name: "SCTV",
  },
  {
    name: "Indosiar",
  },
  {
    name: "RCTI",
  },
  {
    name: "GTV",
  },
  {
    name: "ANTV",
  },
  {
    name: "Jak TV",
  },
  {
    name: "BTV",
  },
  {
    name: "Kompas TV",
  },
  {
    name: "rtv",
  },
  {
    name: "MNCTV",
  },
  {
    name: "NET TV",
  },
  {
    name: "TVRI",
  },
  {
    name: "jtv",
  },
  {
    name: "Zee Bioskop",
  },
  {
    name: "Movies News HD",
  },
  {
    name: "tvN HD",
  },
  {
    name: "dizi",
  },
  {
    name: "Warner TV HD",
  },
  {
    name: "KBS World HD",
  },
  {
    name: "Gossip",
  },
  {
    name: "Gaming TV HD",
  },
  {
    name: "Travel TV HD",
  },
  {
    name: "Fashion TV HD",
  },
  {
    name: "Pretty TV HD",
  },
  {
    name: "Discovery Science HD",
  },
  {
    name: "Discovery Channel HD",
  },
  {
    name: "Knowledge+",
  },
  {
    name: "English Club TV HD",
  },
  {
    name: "How To HD",
  },
  {
    name: "DW",
  },
  {
    name: "France 24",
  },
  {
    name: "CGTN",
  },
  {
    name: "Cartoon TV Classics HD",
  },
  {
    name: "Fight TV HD",
  },
  {
    name: "Xtrem Sports HD",
  },
  {
    name: "Horizon Sports HD",
  },
  {
    name: "AXN HD",
  },
  {
    name: "TechStorm HD",
  },
  {
    name: "Asian Food Network HD",
  },
  {
    name: "Motor Racing HD",
  },
  {
    name: "Eurosport HD",
  },
  {
    name: "CGTN Documentary",
  },
  {
    name: "CNN International HD",
  },
  {
    name: "CNBC Asia",
  },
  {
    name: "Bloomberg TV",
  },
  {
    name: "Cartoon TV Premium",
  },
  {
    name: "Cartoon TV",
  },
  {
    name: "Clubbing TV",
  },
  {
    name: "HBO HD",
  },
  {
    name: "HBO Signature HD",
  },
  {
    name: "HBO Hits HD",
  },
  {
    name: "HBO Family HD",
  },
  {
    name: "CINEMAX HD",
  },
  {
    name: "Cinema World HD",
  },
  {
    name: "ONE HD",
  },
  {
    name: "Animax ",
  },
  {
    name: "Cooking TV HD",
  },
  {
    name: "Cartoon Network HD",
  },
  {
    name: "Boomerang HD",
  },
  {
    name: "SuperYacht TV HD",
  },
  {
    name: "Speed TV HD",
  },
  {
    name: "Sports TV HD",
  },
];

export default TVChannel;
