import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { Helmet } from "react-helmet";
import { IoCheckmarkDoneCircle, IoCloseCircle } from "react-icons/io5";
import { IconContext } from "react-icons/lib";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { getValidateInvoice } from "../../features/invoiceSlice";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import loadingMenu from "../../assets/img/loading-menu.gif";
import { Toast } from "react-bootstrap";

const ValidateInvoice = () => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector((state: any) => state.invoice);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invoiceId = queryParams.get("i");
  const signature = queryParams.get("s");

  const [responseInvoice, setResponseInvoice] = useState("");
  const [toastFormInvoice, setToastFormInvoice] = useState<boolean>(false);
  const [token, setToken] = useState("");
  const [validated, setValidated] = useState<boolean>(false);

  const onVerify = useCallback((token: any) => {
    setToken(token);
  }, []);

  useEffect(() => {
    if (!signature) {
      navigate("/");
    }

    dispatch(
      getValidateInvoice({
        i: invoiceId,
        s: signature,
        tkndkd: token,
      })
    )
      .unwrap()
      .then((res) => {
        setToastFormInvoice(true);
        setResponseInvoice(res.msg);
        setValidated(true);
        if (res.status === false) {
          setValidated(false);
          throw new Error(res.msg);
        }
      })
      .catch((err) => {
        setToastFormInvoice(true);
      });
  }, [token]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Validasi Tagihan</title>
      </Helmet>

      <Header page="success-order" />

      {/* disini nanti berhasil */}
      <div className="container py-5">
        {loading ? (
          <>
            <h1
              className="text-center"
              style={{ fontSize: "4em", fontWeight: "900", color: "#df0303" }}
            >
              Sedang Memvalidasi...
            </h1>
            <div className="d-flex justify-content-center">
              <img src={loadingMenu} alt="dukodu" width={100} height={100} />
            </div>
          </>
        ) : validated ? (
          <>
            <h1
              className="text-center"
              style={{ fontSize: "4em", fontWeight: "900", color: "#df0303" }}
            >
              Tagihan Berhasil Divalidasi
            </h1>

            <div className="d-flex justify-content-center">
              <IconContext.Provider value={{ color: "#DF0303", size: "250px" }}>
                <IoCheckmarkDoneCircle />
              </IconContext.Provider>
            </div>
          </>
        ) : (
          <>
            <h1
              className="text-center"
              style={{ fontSize: "4em", fontWeight: "900", color: "#df0303" }}
            >
              Tagihan Tidak Valid
            </h1>

            <div className="d-flex justify-content-center">
              <IconContext.Provider value={{ color: "#DF0303", size: "250px" }}>
                <IoCloseCircle />
              </IconContext.Provider>
            </div>
          </>
        )}

        <div className="mt-3">
          <div className="text-center">
            Tagihan anda telah kami validasi. Terima Kasih sudah memilih paket
            Promo kami dan sudah menjadi pelanggan setia DUKODU.
            <br />
            <br />
            PROMO lainnya di website{" "}
            <a href="https://dukodu.id">https://dukodu.id</a> atau hubungi kami
            di{" "}
            <a href="https://wa.me/628115634634">https://wa.me/628115634634</a>{" "}
            dan email ke cs@dukodu.id apabila Bapak/Ibu membutuhkan informasi
            maupun bantuan lebih lanjut.
          </div>
        </div>

        <div className="mt-3 d-flex justify-content-center">
          <div className="theme-btn" onClick={() => navigate("/")}>
            {"Back To Home"}
          </div>
        </div>
      </div>

      <GoogleReCaptchaProvider reCaptchaKey="6Ldxxw4bAAAAAHVBc271fWbPo5tLPiq1hlslR17a">
        <GoogleReCaptcha onVerify={onVerify} />
      </GoogleReCaptchaProvider>

      <Footer />

      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <Toast
          onClose={() => setToastFormInvoice(false)}
          show={toastFormInvoice}
          delay={1500}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">DUKODU</strong>
          </Toast.Header>
          <Toast.Body>{responseInvoice}</Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default ValidateInvoice;
