import { configureStore } from "@reduxjs/toolkit";
import contactReducer from "../features/contactSlice";
import discoverServiceReducer from "../features/discoverServiceSlice";
import featureReducer from "../features/featureSlice";
import faqReducer from "../features/faqSlice";
import serviceReducer from "../features/serviceSlice";
import testimoniReducer from "../features/testimoniSlice";
import productReducer from "../features/productSlice";
import blogReducer from "../features/blogSlice";
import upgradeReducer from "../features/upgradeSlice";
import invoiceReducer from "../features/invoiceSlice";

export const store = configureStore({
  reducer: {
    contact: contactReducer,
    discoverService: discoverServiceReducer,
    feature: featureReducer,
    service: serviceReducer,
    testimoni: testimoniReducer,
    product: productReducer,
    faq: faqReducer,
    blog: blogReducer,
    upgrade: upgradeReducer,
    invoice: invoiceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
