import { Helmet } from "react-helmet";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { useLocation, useNavigate } from "react-router-dom";
import { InvoiceData } from "../../utils/types";
import { useState, useEffect, useCallback } from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Form,
  Modal,
  Nav,
  Toast,
} from "react-bootstrap";
import { IoSearch, IoArrowDown, IoReceipt, IoClose } from "react-icons/io5";
import {
  getInvoiceData,
  getListBankVAStatic,
  getOTP,
  postCancelPaymentMethod,
  postPayInvoice,
  postRequestOTPCancelStatic,
  postUpdateBank,
  postValidateOTPCancelStatic,
} from "../../features/invoiceSlice";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import headerInvoice from "../../assets/img/headerInvoice.png";
import footerInvoice from "../../assets/img/footerInvoice.png";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
  Svg,
  Line,
} from "@react-pdf/renderer";
import React from "react";

declare global {
  interface Window {
    snap: any; // Replace 'any' with the appropriate type for the snap library
  }
}

const initialDataInvoice: InvoiceData = {
  phone: "",
};

const CheckInvoice = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const { loading } = useAppSelector((state: any) => state.invoice);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const nohp = queryParams.get("p");

  const [statusInvoice, setStatusInvoice] = useState<string>("all");
  const [dataInvoice, setDataInvoice] = useState<any>();
  const [formInvoice, setFormInvoice] =
    useState<InvoiceData>(initialDataInvoice);
  const [responseInvoice, setResponseInvoice] = useState("");
  const [toastFormInvoice, setToastFormInvoice] = useState<boolean>(false);
  const [errorFormInvoice, setErrFormInvoice] = useState({
    name: "",
    phone: "",
  });
  const [disableCekTagihan, setDisableCekTagihan] = useState(false);
  const [disableBayarTagihan, setDisableBayarTagihan] = useState(false);
  // const [token, setToken] = useState("");
  const [showCancelPayment, setShowCancelPayment] = useState(false);

  //modal type
  const [modalType, setModalType] = useState("activate");

  //VA Static
  const [dataBankVAStatic, setDataBankVAStatic] = useState<any>();
  const [selectedBank, setSelectedBank] = useState<any>("");
  const [hideEdit, setHideEdit] = useState<boolean>(false);
  const [otp, setOTP] = useState<string>("");
  const [modalOTP, setModalOTP] = useState<boolean>(false);

  const handleCancelPayment = useCallback(() => {
    setShowCancelPayment(!showCancelPayment);
  }, [showCancelPayment]);

  const handleChangeForm = useCallback(
    (e: any) => {
      const { name, value } = e.target;

      if (name === "phone") {
        if (isNaN(value as any)) {
          e.target.value = "";
        } else {
          setFormInvoice({ ...formInvoice, [name]: value });
        }
      } else {
        setFormInvoice({ ...formInvoice, [name]: value });
      }

      // validate the input value
      let errorMessage = "";

      // validate no hp
      if (name === "phone" && value.length < 9) {
        errorMessage = "Nomor handphone minimal 9 angka";
      } else if (name === "phone" && value.length > 14) {
        errorMessage = "Nomor handphone maksimal 14 angka";
      }

      setErrFormInvoice({ ...errorFormInvoice, [name]: errorMessage });
    },
    [formInvoice]
  );

  const handleChangeOTP = (event: any) => {
    setOTP(event.target.value);
  };

  const handleChangeBank = (event: any) => {
    setSelectedBank(event.target.value);
  };

  const updateBankVA = async (e: any) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const tokenUpdateBank = await executeRecaptcha("get_updatebank");

    let payload = new FormData();

    payload.append("p", dataInvoice.customer.phone);
    payload.append("bid", selectedBank);
    payload.append("tkndkd", tokenUpdateBank);
    payload.append("otp", otp);

    if (!loading) {
      setDisableBayarTagihan(true);
      dispatch(postUpdateBank(payload))
        .unwrap()
        .then((res) => {
          setResponseInvoice(res.msg);
          if (res.status === false) {
            throw new Error(res.msg);
          }
          setDisableBayarTagihan(false);
          setModalOTP(false);
          setHideEdit(false);
          submitCekTagihan(e);
        })
        .catch((err) => {
          setDisableBayarTagihan(false);
          setOTP("");
          setToastFormInvoice(true);
        });
    }
  };

  const submitCekTagihan = async (e: any) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const tokenCekTagihan = await executeRecaptcha("get_cekinvoice");
    const tokenListBankVAStatic = await executeRecaptcha("get_listbankva");

    const currentTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds

    if (!disableCekTagihan) {
      setDataInvoice([]);
      setDataBankVAStatic([]);
      dispatch(
        getInvoiceData({
          p: formInvoice.phone,
          rt: currentTimestamp,
          s: statusInvoice,
          tkndkd: tokenCekTagihan,
        })
      )
        .unwrap()
        .then((res) => {
          setToastFormInvoice(true);
          setResponseInvoice(res.msg);
          if (res.status === false) {
            throw new Error(res.msg);
          } else {
            setDataInvoice(res.data);
          }
        })
        .catch((err) => {
          setDataInvoice(undefined);
          setToastFormInvoice(true);
        });

      dispatch(
        getListBankVAStatic({
          tkndkd: tokenListBankVAStatic,
        })
      )
        .unwrap()
        .then((res) => {
          setResponseInvoice(res.msg);
          if (res.status === false) {
            throw new Error(res.msg);
          } else {
            setDataBankVAStatic(res.data);
          }
        })
        .catch((err) => {
          setDataBankVAStatic(undefined);
        });
    }
  };

  const getOTPData = async (e: any) => {
    e.preventDefault();

    if (selectedBank === "") {
      return;
    }

    if (!executeRecaptcha) {
      return;
    }

    const tokenGetOTP = await executeRecaptcha("get_getotp");

    if (!disableCekTagihan) {
      dispatch(
        getOTP({
          tkndkd: tokenGetOTP,
          p: formInvoice.phone,
        })
      )
        .unwrap()
        .then((res) => {
          setResponseInvoice(res.msg);
          if (res.status === false) {
            throw new Error(res.msg);
          }
        })
        .catch((err) => {
          setOTP("");
          setToastFormInvoice(true);
        });
    }
  };

  const requestOTPCancelStatic = async (e: any) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const tokenReqOTPCancel = await executeRecaptcha("post_reqotpcancel");

    if (!disableCekTagihan) {
      dispatch(
        postRequestOTPCancelStatic({
          tkndkd: tokenReqOTPCancel,
          p: formInvoice.phone,
        })
      )
        .unwrap()
        .then((res) => {
          setResponseInvoice(res.msg);
          if (res.status === false) {
            throw new Error(res.msg);
          }
        })
        .catch((err) => {
          setOTP("");
          setToastFormInvoice(true);
        });
    }
  };

  const cancelVAStatic = async (e: any) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const tokenCancelVAStatic = await executeRecaptcha("post_cancelvastatic");

    let payload = new FormData();

    payload.append("p", dataInvoice.customer.phone);
    payload.append("tkndkd", tokenCancelVAStatic);
    payload.append("otp", otp);

    if (!loading) {
      setDisableBayarTagihan(true);
      dispatch(postValidateOTPCancelStatic(payload))
        .unwrap()
        .then((res) => {
          setResponseInvoice(res.msg);
          if (res.status === false) {
            throw new Error(res.msg);
          }
          setDisableBayarTagihan(false);
          setModalOTP(false);
          setHideEdit(false);
          submitCekTagihan(e);
        })
        .catch((err) => {
          setDisableBayarTagihan(false);
          setOTP("");
          setToastFormInvoice(true);
        });
    }
  };

  const cancelPaymentMethod = async (e: any, i: string) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const tokencancel = await executeRecaptcha("get_cancel");

    const currentTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds

    let payload = new FormData();

    // payload.append("p", dataInvoice.customer.phone);
    payload.append("rt", currentTimestamp.toString());
    payload.append("i", i);
    payload.append("tkndkd", tokencancel);

    if (!loading) {
      dispatch(postCancelPaymentMethod(payload))
        .unwrap()
        .then((res) => {
          setToastFormInvoice(true);
          setResponseInvoice(res.msg);
          if (res.status === false) {
            throw new Error(res.msg);
          } else {
            handleCancelPayment();
            // window.location.reload();
            submitCekTagihan(e);
          }
        })
        .catch((err) => {
          setToastFormInvoice(true);
        });
    }
  };

  const payInvoice = async (e: any, i: string, t: string) => {
    e.preventDefault();

    if (!executeRecaptcha) {
      return;
    }

    const tokenBayarTagihan = await executeRecaptcha("pay_invoice");

    const currentTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
    // const rt = currentTimestamp + 60; // Adding 60 seconds for 1 minute

    let payload = new FormData();

    payload.append("p", dataInvoice.customer.phone);
    payload.append("rt", currentTimestamp.toString());
    payload.append("i", i);
    payload.append("t", t);
    payload.append("tkndkd", tokenBayarTagihan);

    if (!loading) {
      setDisableBayarTagihan(true);
      dispatch(postPayInvoice(payload))
        .unwrap()
        .then((res) => {
          window.snap.pay(res.data.transaction_token, {
            onSuccess: (result: any) => {
              /* You may add your own implementation here */
              // alert("Payment success!");
              navigate(`/finish-invoice`);
            },
            onPending: (result: any) => {
              /* You may add your own implementation here */
              // alert("Waiting for your payment!");

              navigate(`/cek-tagihan?p=${dataInvoice.customer.phone}`);

              submitCekTagihan(e);
            },
            onError: (result: any) => {
              /* You may add your own implementation here */
              // alert("Payment failed!");
              navigate("/error-payment");
            },
            onClose: () => {
              /* You may add your own implementation here */
              // alert("You closed the popup without finishing the payment");
              navigate(`/cek-tagihan?p=${dataInvoice.customer.phone}`);
            },
          });
          setDisableBayarTagihan(false);
        })
        .catch((err) => {
          setDisableBayarTagihan(false);
          // console.log(err);
          // setRefreshReCaptcha((r) => !r);
        });
    }
  };

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const tokena = await executeRecaptcha("get_invoice");
    const tokenListBankVAStatic = await executeRecaptcha("get_listbankva");
    // Do whatever you want with the token
    const currentTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
    // const rt = currentTimestamp + 60; // Adding 60 seconds for 1 minute

    if (nohp !== null || formInvoice.phone !== "") {
      dispatch(
        getInvoiceData({
          p: nohp ? nohp : formInvoice.phone,
          rt: currentTimestamp,
          s: statusInvoice,
          tkndkd: tokena,
        })
      )
        .unwrap()
        .then((res) => {
          setToastFormInvoice(true);
          setResponseInvoice(res.msg);
          if (res.status === false) {
            throw new Error(res.msg);
          } else {
            setDataInvoice(res.data);
          }
        })
        .catch((err) => {
          setToastFormInvoice(true);
        });

      dispatch(
        getListBankVAStatic({
          tkndkd: tokenListBankVAStatic,
        })
      )
        .unwrap()
        .then((res) => {
          setResponseInvoice(res.msg);
          if (res.status === false) {
            throw new Error(res.msg);
          } else {
            setDataBankVAStatic(res.data);
          }
        })
        .catch((err) => {
          setDataBankVAStatic(undefined);
        });
    }

    if (nohp === null) {
    } else if (nohp.length < 9) {
      setFormInvoice({ ...formInvoice, phone: nohp });
      setErrFormInvoice({
        ...errorFormInvoice,
        phone: "Nomor handphone minimal 9 angka",
      });
    } else if (nohp.length > 14) {
      setFormInvoice({ ...formInvoice, phone: nohp });
      setErrFormInvoice({
        ...errorFormInvoice,
        phone: "Nomor handphone maksimal 14 angka",
      });
    } else {
      setFormInvoice({ ...formInvoice, phone: nohp });
    }
  }, [executeRecaptcha, statusInvoice, nohp]);

  // const handleReCaptchaCancel = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     return;
  //   }

  //   const tokenc = await executeRecaptcha("get_cancel");
  //   setToken(tokenc);
  // }, []);

  useEffect(() => {
    if (formInvoice.phone === "" && nohp === null) {
      setDisableCekTagihan(true);
    } else if (errorFormInvoice.phone !== "") {
      setDisableCekTagihan(true);
    } else {
      setDisableCekTagihan(false);
    }
  }, [formInvoice.phone]);

  useEffect(() => {
    if (loading === false) {
      setDisableCekTagihan(false);
    } else {
      setDisableCekTagihan(true);
    }
  }, [loading]);

  useEffect(() => {
    const snapSrcUrl = "https://app.midtrans.com/snap/snap.js";
    const myMidtransClientKey = "Mid-client-6Cuv3_KHB69RVw2h"; //change this according to your client-key

    const script = document.createElement("script");
    script.src = snapSrcUrl;
    script.setAttribute("data-client-key", myMidtransClientKey);
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // useEffect(() => {
  //   const currentTimestamp = Math.floor(Date.now() / 1000); // Current Unix timestamp in seconds
  //   // const rt = currentTimestamp + 60; // Adding 60 seconds for 1 minute

  //   // disini errornya
  //   if (nohp !== null || formInvoice.phone !== "") {
  //     dispatch(
  //       getInvoiceData({
  //         p: nohp ? nohp : formInvoice.phone,
  //         rt: currentTimestamp,
  //         s: statusInvoice,
  //         tkndkd: token,
  //       })
  //     )
  //       .unwrap()
  //       .then((res) => {
  //         setToastFormInvoice(true);
  //         setResponseInvoice(res.msg);
  //         if (res.status === false) {
  //           throw new Error(res.msg);
  //         } else {
  //           setDataInvoice(res.data);
  //         }
  //       })
  //       .catch((err) => {
  //         setToastFormInvoice(true);
  //       });
  //   }

  //   if (nohp === null) {
  //   } else if (nohp.length < 9) {
  //     setFormInvoice({ ...formInvoice, phone: nohp });
  //     setErrFormInvoice({
  //       ...errorFormInvoice,
  //       phone: "Nomor handphone minimal 9 angka",
  //     });
  //   } else if (nohp.length > 14) {
  //     setFormInvoice({ ...formInvoice, phone: nohp });
  //     setErrFormInvoice({
  //       ...errorFormInvoice,
  //       phone: "Nomor handphone maksimal 14 angka",
  //     });
  //   } else {
  //     setFormInvoice({ ...formInvoice, phone: nohp });
  //   }
  // }, [statusInvoice]);

  useEffect(() => {
    handleReCaptchaVerify();
    // handleCancelPaymentCaptcha();
  }, [handleReCaptchaVerify]);

  // useEffect(() => {
  //   handleReCaptchaCancel();
  //   // handleCancelPaymentCaptcha();
  // }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dukodu - Cek Tagihan</title>
      </Helmet>
      <Header page="cek-tagihan" />
      <div className="container mt-3">
        <div className="py-3">
          <div className="text-center">
            <div className="fs-32 fw-bold t-red">Cek Tagihan</div>
            <div>Periksa tagihan anda dengan nomor handphone.</div>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-12">
            <div className="p-5">
              <form onSubmit={submitCekTagihan}>
                <div className="row d-flex align-items-start">
                  <div
                    className="col-md-9 col-12"
                    style={{ paddingTop: "2px" }}
                  >
                    <Form.Group controlId="phone" className="mb-3">
                      <Form.Control
                        required
                        className="py-2 text-secondary"
                        type="text"
                        placeholder="Masukan No. Handphone"
                        value={formInvoice?.phone}
                        minLength={9}
                        maxLength={14}
                        isInvalid={errorFormInvoice.phone === "" ? false : true}
                        isValid={
                          errorFormInvoice.phone === "" &&
                          formInvoice.phone !== ""
                            ? true
                            : false
                        }
                        name="phone"
                        onChange={(e) => handleChangeForm(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errorFormInvoice.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-md-3 col-12">
                    <button
                      type="submit"
                      className={`${
                        disableCekTagihan ? "theme-btn-disabled" : "theme-btn"
                      } fw-bold fs-14 d-flex justify-content-center align-items-center w-100 mb-3`}
                      onClick={() =>
                        // (window.location.href = `/cek-tagihan?p=${formInvoice?.phone}`)
                        navigate(`/cek-tagihan?p=${formInvoice.phone}`)
                      }
                      disabled={disableCekTagihan}
                    >
                      <span className="me-2">
                        <IoSearch />
                      </span>
                      Cek Tagihan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="mx-auto pb-5" style={{ maxWidth: "560px" }}>
          {dataInvoice?.customer?.name === undefined ? (
            <div className="text-center">Data Tidak Ditemukan</div>
          ) : (
            <>
              <div className="fw-bold fs-3 text-center">Hasil Cek Tagihan</div>
              <Card className="mb-3">
                <Card.Body>
                  <div className="px-1 d-flex justify-content-between">
                    <div>Nama</div>
                    <div>{dataInvoice?.customer?.name}</div>
                  </div>

                  <div className="px-1 d-flex justify-content-between">
                    <div>No. Handphone</div>
                    <div>{dataInvoice?.customer?.phone}</div>
                  </div>

                  <div className="px-1 d-flex justify-content-between">
                    <div>Status Jaringan</div>
                    <div>
                      {dataInvoice?.customer?.status_softblock === "1" ? (
                        <Badge bg="danger" className="ms-1">
                          Terisolir
                        </Badge>
                      ) : (
                        <Badge bg="success" className="ms-1">
                          Aktif
                        </Badge>
                      )}
                    </div>
                  </div>

                  <div className="px-1 d-flex justify-content-between">
                    <div>Waktu Isolir</div>
                    <div>
                      {dataInvoice?.customer?.status_softblock === "1"
                        ? dataInvoice?.customer?.softblock_at
                        : "-"}
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card className="mb-3" style={{ transition: "ease-out 2s" }}>
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-between px-2 pt-2">
                    <div className="fw-semibold">
                      Akun Virtual Statis (Baru)
                    </div>
                    {dataInvoice?.customer?.selected_bank_virtual_account ? (
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-warning btn-sm"
                          onClick={() => setHideEdit(true)}
                        >
                          {dataInvoice?.customer?.is_static_va
                            ? "Ganti Bank"
                            : "Aktifkan"}
                        </button>
                        {dataInvoice?.customer?.is_static_va ? (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm ms-2"
                            onClick={(e) => {
                              requestOTPCancelStatic(e);
                              setModalType("cancelstatic");
                              setModalOTP(true);
                            }}
                          >
                            Batalkan VA Statis
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => setHideEdit(true)}
                      >
                        Aktifkan
                      </button>
                    )}
                  </div>

                  <hr />

                  {dataInvoice?.customer?.selected_bank_virtual_account ? (
                    <>
                      <div className="px-1 d-flex justify-content-between">
                        <div>Bank (VA Statis)</div>
                        <div className="d-flex">
                          <div>
                            {dataInvoice?.customer
                              ?.selected_bank_virtual_account
                              ? dataInvoice?.customer
                                  ?.selected_bank_virtual_account
                              : "-"}
                          </div>
                        </div>
                      </div>

                      <div className="px-1 d-flex justify-content-between">
                        <div>Status VA Statis</div>
                        <div className="d-flex">
                          <div>
                            {dataInvoice?.customer?.is_static_va
                              ? "Aktif"
                              : "Tidak Aktif"}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {hideEdit ? (
                    <>
                      <div className="mt-2 px-1">
                        Jika anda ingin menggunakan VA Statis maka silahkan
                        memilih bank yang ingin digunakan untuk pembayaran.
                      </div>

                      <div className="px-1 d-flex justify-content-between align-items-center">
                        <div>
                          <label htmlFor="bank-select" className="mb-2 me-2">
                            Pilih bank :
                          </label>
                          <select
                            id="bank-select"
                            value={selectedBank}
                            onChange={handleChangeBank}
                            className="p-2 border rounded"
                          >
                            <option value="" disabled>
                              Pilih bank
                            </option>
                            {dataBankVAStatic?.map((bank: any) => (
                              <option key={bank.id} value={bank.id}>
                                {bank.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="d-flex">
                          <button
                            type="button"
                            className="btn btn-danger btn-sm me-2"
                            onClick={() => setHideEdit(false)}
                          >
                            Batal
                          </button>

                          <Button
                            variant="primary"
                            size="sm"
                            onClick={(e) => {
                              getOTPData(e);
                              setModalType("activate");
                              setModalOTP(true);
                            }}
                            disabled={selectedBank === ""}
                          >
                            Kirim
                          </Button>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </Card.Body>
              </Card>

              <Nav
                justify
                variant="tabs"
                defaultActiveKey="link-0"
                className="mb-2"
              >
                <Nav.Item
                  key={1}
                  onClick={() => {
                    setStatusInvoice("all");
                    setDataInvoice({ ...dataInvoice, inquiry: null });
                    // setRefreshReCaptcha((r) => !r);
                  }}
                >
                  <Nav.Link eventKey="link-0">Semua Tagihan</Nav.Link>
                </Nav.Item>
                <Nav.Item
                  key={2}
                  onClick={() => {
                    setStatusInvoice("settlement");
                    setDataInvoice({ ...dataInvoice, inquiry: null });
                    // setRefreshReCaptcha((r) => !r);
                  }}
                >
                  <Nav.Link eventKey="link-1">Lunas</Nav.Link>
                </Nav.Item>
                <Nav.Item
                  key={3}
                  onClick={() => {
                    setStatusInvoice("pending");
                    setDataInvoice({ ...dataInvoice, inquiry: null });
                    // setRefreshReCaptcha((r) => !r);
                  }}
                >
                  <Nav.Link eventKey="link-2">Belum Lunas</Nav.Link>
                </Nav.Item>
              </Nav>
              {dataInvoice?.inquiry === null ? (
                <div className="text-center py-2">Data Tidak Ditemukan</div>
              ) : (
                <Accordion
                  defaultActiveKey={dataInvoice?.inquiry[0]?.invoice_id}
                >
                  {dataInvoice?.inquiry?.map((item: any) => (
                    <Accordion.Item
                      eventKey={item.invoice_id}
                      key={item.invoice_id}
                    >
                      <Accordion.Header>
                        {item.invoice_id} - {item.invoice_due_date}{" "}
                        {item.invoice_sitename
                          ? `- ${item.invoice_sitename}`
                          : ``}{" "}
                        -{"  "}
                        {item.invoice_status === "settlement" ? (
                          <Badge bg="success" className="ms-1">
                            Lunas
                          </Badge>
                        ) : (
                          <Badge bg="warning" className="ms-1">
                            Belum Lunas
                          </Badge>
                        )}
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="d-flex justify-content-between">
                          <div>Status Tagihan</div>
                          <div>
                            {item.invoice_status === "settlement" ? (
                              <>
                                <Badge bg="success" className="me-1">
                                  Lunas
                                </Badge>
                                {item?.invoice_settlement_time}
                              </>
                            ) : (
                              <Badge bg="warning">Belum Lunas</Badge>
                            )}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Nama Paket</div>
                          <div>{item.invoice_package}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Bandwidth Paket</div>
                          <div>{item.invoice_package_bandwidth}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Jumlah Tagihan</div>
                          <div>{item.invoice_total}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Waktu Tagihan Dibuat</div>
                          <div>{item.invoice_issue_date}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Waktu Tenggat Tagihan</div>
                          <div>{item.invoice_due_date}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Addons</div>
                          <div>
                            {item.invoice_addons !== null
                              ? item.invoice_addons.map((datum: any) => (
                                  <div>• {datum.addons}</div>
                                ))
                              : "Tidak ada"}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>Validasi Tagihan</div>
                          <div>
                            <img
                              src={item.invoice_qr}
                              alt="dukodu"
                              style={{ width: "80px", height: "80px" }}
                            />
                          </div>
                        </div>

                        {item.invoice_status !== "settlement" && (
                          <>
                            <hr />
                            <div className="d-flex justify-content-evenly">
                              <PDFDownloadButton
                                name={dataInvoice?.customer?.name}
                                invoice_id={item?.invoice_id}
                                dataDetailInvoice={item}
                              />
                              <Button
                                variant="success"
                                onClick={(e) =>
                                  payInvoice(e, item.id, item.invoice_signature)
                                }
                                disabled={disableBayarTagihan}
                                id="pay-button"
                              >
                                <span className="me-2">
                                  <IoReceipt />
                                </span>
                                Bayar Sekarang
                              </Button>
                            </div>
                            {item?.invoice_trx_id ? (
                              <div className="d-flex justify-content-center mt-3">
                                <Button
                                  variant="danger"
                                  onClick={() => {
                                    handleCancelPayment();
                                  }}
                                  id="cancel-button"
                                >
                                  <span className="me-2">
                                    <IoClose />
                                  </span>
                                  Batalkan Transaksi
                                </Button>

                                <Modal
                                  show={showCancelPayment}
                                  onHide={handleCancelPayment}
                                  centered
                                  backdrop="static"
                                  keyboard={false}
                                >
                                  <Modal.Body>
                                    Apakah anda yakin ingin membatalkan
                                    transaksi ?
                                  </Modal.Body>
                                  <Modal.Footer>
                                    <Button
                                      variant="secondary"
                                      onClick={handleCancelPayment}
                                    >
                                      Tidak
                                    </Button>
                                    <Button
                                      variant="primary"
                                      onClick={(e) =>
                                        cancelPaymentMethod(e, item.id)
                                      }
                                    >
                                      Ya, saya yakin
                                    </Button>
                                  </Modal.Footer>
                                </Modal>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )}

                        {item.invoice_status === "settlement" && (
                          <>
                            <hr />
                            <div className="d-flex justify-content-center">
                              <PDFDownloadButton
                                name={dataInvoice?.customer?.name}
                                invoice_id={item?.invoice_id}
                                dataDetailInvoice={item}
                              />
                            </div>
                          </>
                        )}

                        {/* {item.invoice_status === "expire" && (
                          <>
                            <hr />
                            <div className="d-flex justify-content-center">
                              <PDFDownloadButton
                                name={dataInvoice?.customer?.name}
                          
                                invoice_id={item?.invoice_id}
                                dataDetailInvoice={item}
                              />
                            </div>
                          </>
                        )} */}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              )}
            </>
          )}
        </div>
      </div>

      {/* <GoogleReCaptchaProvider reCaptchaKey="6Ldxxw4bAAAAAHVBc271fWbPo5tLPiq1hlslR17a">
        <GoogleReCaptcha
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
        />
      </GoogleReCaptchaProvider> */}

      <Footer />

      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <Toast
          onClose={() => setToastFormInvoice(false)}
          show={toastFormInvoice}
          delay={1500}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">DUKODU</strong>
          </Toast.Header>
          <Toast.Body>{responseInvoice}</Toast.Body>
        </Toast>
      </div>

      <Modal
        show={modalOTP}
        onHide={() => setModalOTP(false)}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Masukan Kode OTP ({modalType === "activate" ? "Activate" : "Cancel"}
            )
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Silahkan cek aplikasi whatsapp, kami telah mengirim kode OTP.</p>
          <input
            type="text"
            onChange={handleChangeOTP}
            placeholder="Masukan Kode OTP"
            className="form-control"
            maxLength={6}
            minLength={6}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalOTP(false)}>
            Tutup
          </Button>
          <Button
            variant="primary"
            onClick={modalType === "activate" ? updateBankVA : cancelVAStatic}
            disabled={otp.length !== 6}
          >
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const PDFDownloadButton = ({ name, invoice_id, dataDetailInvoice }: any) => {
  return (
    <div>
      <PDFDownloadLink
        document={
          <InvoicePaper
            name={name}
            invoice_id={invoice_id}
            dataDetailInvoice={dataDetailInvoice}
          />
        }
        fileName={`Invoice - ${name} - ${invoice_id} ${
          dataDetailInvoice?.invoice_sitename
            ? `-  ${dataDetailInvoice?.invoice_sitename}`
            : ""
        }.pdf`}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading document..."
          ) : (
            <Button variant="primary">
              <span className="me-2">
                <IoArrowDown />
              </span>
              Unduh Invoice{" "}
            </Button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

Font.register({
  family: "Baloo 2",
  fontStyle: "normal",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdj9yqpv9Fat7WcN.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://fonts.gstatic.com/s/baloo2/v16/wXK0E3kTposypRydzVT08TS3JnAmtdgazapv9Fat7WcN.ttf",
      fontWeight: "normal",
    },
  ],
});

const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
    fontFamily: "Baloo 2",
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "flex",
    width: "auto",
  },
  tableRowHead: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#DF0303",
    borderRadius: 15,
    paddingVertical: 5,
    color: "white",
    fontWeight: "bold",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColNo: {
    width: "5%",
  },
  tableCol: {
    width: "23%",
  },
  tableCell: {
    margin: "auto",
    marginTop: 10,
    fontSize: 12,
  },
  tableCellHead: {
    margin: "auto",
    marginTop: 5,
    fontSize: 14,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const InvoicePaper = ({ name, invoice_id, dataDetailInvoice }: any) => {
  return (
    <Document>
      <Page style={styles.body}>
        <Image src={headerInvoice} style={styles.header} fixed />
        <Text
          style={{
            position: "absolute",
            top: 140,
            right: -15,
            fontSize: 12,
            backgroundColor: "#DF0303",
            paddingRight: 40,
            paddingTop: 8,
            paddingBottom: 8,
            paddingLeft: 10,
            borderRadius: 40,
            fontWeight: "bold",
            color: "white",
          }}
        >
          Invoice: {invoice_id} {"    "} Tanggal:{" "}
          {dataDetailInvoice?.invoice_issue_date}
        </Text>

        <View style={{ paddingHorizontal: 15, top: 30, fontSize: 12 }}>
          <View style={{ paddingVertical: 15, marginHorizontal: 10 }}>
            <Text style={{ fontWeight: "bold" }}>Kepada :</Text>
            <Text>
              {name}{" "}
              {dataDetailInvoice.invoice_sitename
                ? `- ${dataDetailInvoice.invoice_sitename}`
                : ""}
            </Text>
            <Text>{dataDetailInvoice.invoice_installation_address}</Text>
            <View style={{ marginTop: 10 }}>
              <Text style={{ fontWeight: "bold" }}>
                Batas Akhir Pembayaran :
              </Text>
              <Text> {dataDetailInvoice.invoice_due_date}</Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRowHead}>
              <View style={styles.tableColNo}>
                <Text style={styles.tableCellHead}>No</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHead}>Paket</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHead}>Kategori</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHead}>Bandwith</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHead}>Harga</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColNo}>
                <Text style={styles.tableCell}>1</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {dataDetailInvoice.invoice_package}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Paket Internet</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {dataDetailInvoice.invoice_package_bandwidth}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {dataDetailInvoice?.invoice_package_price}
                </Text>
              </View>
            </View>
            <Svg height="10" style={{ width: "100%" }}>
              <Line
                x1="0"
                y1="5"
                x2="800"
                y2="5"
                strokeWidth={0.5}
                stroke="rgb(0,0,0)"
              />
            </Svg>
            {dataDetailInvoice.invoice_addons
              ? dataDetailInvoice.invoice_addons.map(
                  (item: any, index: number) => (
                    <React.Fragment key={index}>
                      <View style={styles.tableRow}>
                        <View style={styles.tableColNo}>
                          <Text style={styles.tableCell}>{index + 2}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{item.addons}</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>Addons</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>-</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {item.addons_price}
                          </Text>
                        </View>
                      </View>
                      <Svg height="10" style={{ width: "100%" }}>
                        <Line
                          x1="0"
                          y1="5"
                          x2="800"
                          y2="5"
                          strokeWidth={0.5}
                          stroke="rgb(0,0,0)"
                        />
                      </Svg>
                    </React.Fragment>
                  )
                )
              : ""}

            {dataDetailInvoice.invoice_adjustment
              ? dataDetailInvoice.invoice_adjustment.map(
                  (item: any, index: number) => (
                    <>
                      <View style={styles.tableRow}>
                        <View style={styles.tableColNo}>
                          <Text style={styles.tableCell}>
                            {dataDetailInvoice.invoice_addons &&
                              dataDetailInvoice.invoice_addons.length +
                                index +
                                2}

                            {dataDetailInvoice.invoice_addons === null &&
                              index + 2}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {item.adjustment_name}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {item.adjustment_type}
                          </Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>-</Text>
                        </View>
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>
                            {item.adjustment_amount}
                          </Text>
                        </View>
                      </View>
                      <Svg height="10" style={{ width: "100%" }}>
                        <Line
                          x1="0"
                          y1="5"
                          x2="800"
                          y2="5"
                          strokeWidth={0.5}
                          stroke="rgb(0,0,0)"
                        />
                      </Svg>
                    </>
                  )
                )
              : ""}

            {dataDetailInvoice.invoice_phone_usage ? (
              <>
                <View style={styles.tableRow}>
                  <View style={styles.tableColNo}>
                    <Text style={styles.tableCell}>
                      {dataDetailInvoice.invoice_addons === null &&
                        dataDetailInvoice.invoice_adjustment === null &&
                        2}

                      {dataDetailInvoice.invoice_addons &&
                        dataDetailInvoice.invoice_adjustment === null &&
                        dataDetailInvoice.invoice_addons?.length + 2}
                      {dataDetailInvoice.invoice_addons === null &&
                        dataDetailInvoice.invoice_adjustment &&
                        dataDetailInvoice.invoice_adjustment?.length + 2}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Pemakaian Telepon</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Addons</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>-</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {dataDetailInvoice?.invoice_phone_usage}
                    </Text>
                  </View>
                </View>
                <Svg height="10" style={{ width: "100%" }}>
                  <Line
                    x1="0"
                    y1="5"
                    x2="800"
                    y2="5"
                    strokeWidth={0.5}
                    stroke="rgb(0,0,0)"
                  />
                </Svg>
              </>
            ) : (
              ""
            )}

            {dataDetailInvoice.invoice_installation_price ? (
              <>
                <View style={styles.tableRow}>
                  <View style={styles.tableColNo}>
                    <Text style={styles.tableCell}>
                      {dataDetailInvoice.invoice_addons &&
                        dataDetailInvoice.invoice_phone_usage &&
                        dataDetailInvoice.invoice_adjustment &&
                        dataDetailInvoice.invoice_addons?.length +
                          dataDetailInvoice.invoice_adjustment?.length +
                          3}
                      {dataDetailInvoice.invoice_addons &&
                        dataDetailInvoice.invoice_phone_usage === null &&
                        dataDetailInvoice.invoice_adjustment &&
                        dataDetailInvoice.invoice_addons?.length +
                          dataDetailInvoice.invoice_adjustment?.length +
                          2}

                      {dataDetailInvoice.invoice_addons &&
                        dataDetailInvoice.invoice_adjustment === null &&
                        3}

                      {dataDetailInvoice.invoice_addons === null &&
                        dataDetailInvoice.invoice_adjustment &&
                        3}
                      {dataDetailInvoice.invoice_addons === null &&
                        dataDetailInvoice.invoice_adjustment === null &&
                        2}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Biaya Instalasi</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Instalasi</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>-</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>
                      {dataDetailInvoice?.invoice_installation_price}
                    </Text>
                  </View>
                </View>
                <Svg height="10" style={{ width: "100%" }}>
                  <Line
                    x1="0"
                    y1="5"
                    x2="800"
                    y2="5"
                    strokeWidth={0.5}
                    stroke="rgb(0,0,0)"
                  />
                </Svg>
              </>
            ) : (
              ""
            )}

            <Svg height="10" style={{ width: "100%", marginTop: 40 }}>
              <Line
                x1="0"
                y1="5"
                x2="800"
                y2="5"
                strokeWidth={0.5}
                stroke="rgb(0,0,0)"
              />
            </Svg>

            <Text style={{ marginBottom: 10, fontWeight: "bold" }}>
              Terbilang : {dataDetailInvoice?.invoice_terbilang}
            </Text>

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "40%",
                marginLeft: 350,
                paddingRight: 30,
              }}
            >
              <Text style={{ width: "57%" }}>Sub Total</Text>
              <Text style={{ width: "43%" }}>
                {dataDetailInvoice?.invoice_sub_total}
              </Text>
            </View>
            {/* 
            {dataDetailInvoice?.invoice_adjustment ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "40%",
                  marginLeft: 350,
                  paddingRight: 30,
                }}
              >
                <Text style={{ width: "57%" }}>Penyesuaian</Text>
                <Text style={{ width: "43%" }}>
                  {dataDetailInvoice?.invoice_adjustment}
                </Text>
              </View>
            ) : (
              ""
            )} */}

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "40%",
                marginLeft: 350,
                paddingRight: 30,
              }}
            >
              <Text style={{ width: "57%" }}>Pajak 11%</Text>
              <Text style={{ width: "43%" }}>
                {dataDetailInvoice?.invoice_tax}
              </Text>
            </View>
            {dataDetailInvoice?.invoice_admin_fee ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "40%",
                  marginLeft: 350,
                  paddingRight: 30,
                }}
              >
                <Text style={{ width: "57%" }}>Biaya Admin</Text>
                <Text style={{ width: "43%" }}>
                  {dataDetailInvoice?.invoice_admin_fee}
                </Text>
              </View>
            ) : (
              ""
            )}
            <Svg height="10" style={{ width: "100%", paddingLeft: 350 }}>
              <Line
                x1="0"
                y1="5"
                x2="800"
                y2="5"
                strokeWidth={0.5}
                stroke="rgb(0,0,0)"
              />
            </Svg>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "40%",
                marginLeft: 350,
                paddingRight: 30,
                fontWeight: "bold",
              }}
            >
              <Text style={{ width: "57%" }}>Total </Text>
              <Text style={{ width: "43%" }}>
                {dataDetailInvoice?.invoice_total}
              </Text>
            </View>
          </View>
        </View>
        {/* <Image
          src={{
            uri: dataDetailInvoice?.invoice_qr,
            method: "GET",
            headers: { origin: "https://dukodu.id" },
            body: "",
          }}
          style={{ width: 100, height: 100, top: 50, marginHorizontal: 15 }}
        /> */}

        <Image src={footerInvoice} style={styles.pageNumber} fixed />
      </Page>
    </Document>
  );
};

export default CheckInvoice;
