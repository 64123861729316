import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import slide1 from "../../assets/img/slide1.webp";
import slide2 from "../../assets/img/slide2.webp";
import slide3 from "../../assets/img/slide3.webp";
import slide4 from "../../assets/img/slide4.webp";
import slide5 from "../../assets/img/slide5.webp";
import "./home.css";
import { Helmet } from "react-helmet";
import React, { Suspense } from "react";

const FeatureContent = React.lazy(
  () => import("../../components/featureContent/featureContent")
);

const DiscoverServiceComponent = React.lazy(
  () => import("../../components/discoverService/discoverService")
);

const PaketContent = React.lazy(
  () => import("../../components/paketContent/paketContent")
);

const Testimoni = React.lazy(
  () => import("../../components/testimoni/testimoni")
);

const Faq = React.lazy(() => import("../../components/faq/faq"));

const Introduction = React.lazy(
  () => import("../../components/introduction/introduction")
);

const Home = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dukodu - Home</title>
      </Helmet>

      <Header page="home" />

      {/* carousel information */}
      <div
        id="carouselExampleControls"
        className="carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active" data-bs-interval="2500">
            {/* <LazyLoadImage
              alt="dukodu"
              width={"100%"}
              height={"100%"}
              src={slide1}
              className="d-block w-100"
            /> */}
            <img src={slide1} className="d-block w-100 h-100" alt="dukodu" />

            {/* <div className="carousel-caption d-none d-md-block">
              <h5 className="mb-4">Selamat Datang Di Dukodu!</h5>
              <p className="w-50 fs-20 fw-semibold mb-4">
                Membuka informasi dari Dusun ke Kota dan Dunia, Membawa
                informasi dari Dunia dan Kota ke Dusun - Dukodu
              </p>
              <div className="d-flex">
                <a
                  href="https://wa.me/6281215550572?text=Halo%20Dukodu,%20saya%20mau%20berlangganan%20internet%20super%20cepat"
                  className="theme-btn"
                  target={"_blank"}
                >
                  Berlangganan Sekarang
                </a>
              </div>
            </div> */}
          </div>
          <div className="carousel-item" data-bs-interval="2500">
            {/* <LazyLoadImage
              alt="dukodu"
              width={"100%"}
              src={slide2}
              className="d-block w-100"
            /> */}
            <img src={slide2} className="d-block w-100 h-100" alt="dukodu" />
          </div>
          <div className="carousel-item" data-bs-interval="2500">
            {/* <LazyLoadImage
              alt="dukodu"
              width={"100%"}
              src={slide4}
              className="d-block w-100"
            /> */}
            <img src={slide4} className="d-block w-100 h-100" alt="dukodu" />
          </div>
          <div className="carousel-item" data-bs-interval="2500">
            {/* <LazyLoadImage
              alt="dukodu"
              width={"100%"}
              src={slide3}
              className="d-block w-100"
            /> */}
            <img src={slide3} className="d-block w-100 h-100" alt="dukodu" />
          </div>
          <div className="carousel-item" data-bs-interval="2500">
            {/* <LazyLoadImage
              alt="dukodu"
              width={"100%"}
              src={slide5}
              className="d-block w-100"
            /> */}
            <img src={slide5} className="d-block w-100 h-100" alt="dukodu" />
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleControls"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* feature section */}
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              minHeight: 565,
            }}
          >
            Mengambil Data
          </div>
        }
      >
        <FeatureContent />
      </Suspense>

      {/* service find location */}
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              minHeight: 242,
            }}
          >
            Mengambil Data
          </div>
        }
      >
        <DiscoverServiceComponent />
      </Suspense>

      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              minHeight: 1000,
            }}
          >
            Mengambil Data
          </div>
        }
      >
        {/* about us */}
        <Introduction />
      </Suspense>

      {/* product */}
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              minHeight: 920,
            }}
          >
            Mengambil Data
          </div>
        }
      >
        {" "}
        <PaketContent page="home" />
      </Suspense>

      {/* faq */}
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              minHeight: 560,
            }}
          >
            Mengambil Data
          </div>
        }
      >
        <Faq />
      </Suspense>

      {/* testimonial */}
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              minHeight: 460,
            }}
          >
            Mengambil Data
          </div>
        }
      >
        <Testimoni />
      </Suspense>

      <Footer />
    </>
  );
};

export default Home;
