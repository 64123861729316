import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { DiscoverServiceState } from "../utils/interfaces";

const initialState: DiscoverServiceState = {
  loading: false,
  data: {},
};

const config = {
  headers: { "content-type": "multipart/form-data" },
};

//post discover service
export const postDiscoverService = createAsyncThunk(
  "post/postDiscoverService",
  async (data: any) => {
    try {
      const response = await axios.post(`/api/qna/coverage`, data, config);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

//get provinsi
export const getProvinsi = createAsyncThunk("get/getProvinsi", async () => {
  try {
    const response = await axios.get(`/api/data/provinsi`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

//get kabupaten by id provinsi
export const getKabupaten = createAsyncThunk(
  "get/getKabupaten",
  async (data: any) => {
    try {
      const response = await axios.get(`/api/data/kabupaten?provinsi=${data}`);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

//get kecamatan by id kabupaten
export const getKecamatan = createAsyncThunk(
  "get/getKecamatan",
  async (data: any) => {
    try {
      const response = await axios.get(`/api/data/kecamatan?kabupaten=${data}`);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

//get kelurahan by id kecamatan
export const getKelurahan = createAsyncThunk(
  "get/getKelurahan",
  async (data: any) => {
    try {
      const response = await axios.get(`/api/data/kelurahan?kecamatan=${data}`);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const discoverServiceSlice = createSlice({
  name: "discoverService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //post discover service
      .addCase(postDiscoverService.pending, (state) => {
        state.loading = true;
      })
      .addCase(postDiscoverService.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postDiscoverService.rejected, (state, action) => {
        state.loading = false;
      })

      //get provinsi
      .addCase(getProvinsi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProvinsi.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getProvinsi.rejected, (state) => {
        state.loading = false;
      })

      //get kabupaten by id provinsi
      .addCase(getKabupaten.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKabupaten.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getKabupaten.rejected, (state) => {
        state.loading = false;
      })

      //get kecamatan by id kabupaten
      .addCase(getKecamatan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKecamatan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getKecamatan.rejected, (state) => {
        state.loading = false;
      })

      //get kelurahan by id kecamatan
      .addCase(getKelurahan.pending, (state) => {
        state.loading = true;
      })
      .addCase(getKelurahan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getKelurahan.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default discoverServiceSlice.reducer;
