import { Helmet } from "react-helmet";

const NotFound = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Not Found</title>
      </Helmet>

      <div className="vh-100 bg-second d-flex justify-content-center align-items-center">
        <div className="d-flex flex-column align-items-center rounded-2">
          <h2 className="fw-bold t-red">404</h2>
          <h6>Page Not Found</h6>
        </div>
      </div>
    </>
  );
};

export default NotFound;
