type ThumbnailProps = {
  title: string;
};

const ThumbnailPage = ({ title }: ThumbnailProps): JSX.Element => {
  return (
    <section>
      <div className="bg-thumb">
        <div className="bg-layer h-100 d-flex justify-content-center align-items-center">
          <div className="t-white fs-3">{title}</div>
        </div>
      </div>
    </section>
  );
};

export default ThumbnailPage;
