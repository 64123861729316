import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ContactState } from "../utils/interfaces";

const initialState: ContactState = {
  loading: false,
  data: {},
};

const config = {
  headers: { "content-type": "multipart/form-data" },
};

export const postFormContact = createAsyncThunk(
  "post/postFormContact",
  async (data: any) => {
    try {
      const response = await axios.post(`/api/contact/inquiry`, data, config);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postFormContact.pending, (state) => {
        state.loading = true;
      })
      .addCase(postFormContact.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postFormContact.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default contactSlice.reducer;
