import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hook";
import { getFeatureData } from "../../features/featureSlice";
import { FeatureData } from "../../utils/types";
import { motion } from "framer-motion";
import { bottomUp } from "../animate/animate";

const FeatureContent = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const feature = useAppSelector((state) => state.feature);

  const [featureData, setFeatureData] = useState<FeatureData[]>([]);

  useEffect(() => {
    dispatch(getFeatureData())
      .unwrap()
      .then((res) => {
        let newFeatureData = { ...featureData };
        newFeatureData = res.data;
        setFeatureData(newFeatureData);
      })
      .catch(() => {});
  }, []);

  return (
    <section className="bg-second">
      <div className="feature container py-5">
        {/* <div className="d-flex justify-content-center">
            <h5 className="t-red fw-semibold">FITUR</h5>
          </div> */}
        <div className="d-flex justify-content-center mb-3">
          <h3 className="gradient-subtitle">Fitur Terbaik Kami</h3>
        </div>

        <div className="card-feature row mt-5">
          {featureData.map((item: FeatureData) => (
            <div className="col-lg-3 content-feature mb-3" key={item.id}>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.6 }}
                variants={bottomUp}
              >
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <img
                        src={item.thumbnail}
                        alt="dukodu"
                        style={{ width: "80px", height: "80px" }}
                      />
                    </div>
                    <p className="card-title py-3 fw-semibold title-feature fs-4 text-break text-center">
                      {item.name}
                    </p>
                    <div className="card-text text-secondary desc-feature mt-lg-5 text-center">
                      {item.description}
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureContent;
