import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ServiceState } from "../utils/interfaces";

const initialState: ServiceState = {
  loading: false,
  data: {},
  error: {},
};

export const resendOTP = createAsyncThunk(
  "resend/resendOTP",
  async (data: any) => {
    try {
      const response = await axios.get(
        `/api/promo/confidential/otp/resend?_=${Math.random()}`,
        {
          params: data,
        }
      );
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const postOTP = createAsyncThunk("post/postOTP", async (data: any) => {
  try {
    const response = await axios.post(
      `/api/promo/confidential/otp/submit`,
      data
    );
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

// the api not yet served
export const postOTPInvoice = createAsyncThunk(
  "post/postOTPInvoice",
  async (data: any) => {
    try {
      const response = await axios.post(
        `/api/promo/confidential/otp/submit`,
        data
      );
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const otpSlice = createSlice({
  name: "otp",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // resend otp
      .addCase(resendOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendOTP.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.loading = false;
      })

      // verification otp upgrade
      .addCase(postOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(postOTP.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postOTP.rejected, (state, action) => {
        state.loading = false;
      })

      // verification otp invoice
      .addCase(postOTPInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(postOTPInvoice.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postOTPInvoice.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default otpSlice.reducer;
