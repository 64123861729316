import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ProductState } from "../utils/interfaces";

const initialState: ProductState = {
  loading: false,
  data: {},
};

export const getProductData = createAsyncThunk(
  "get/getProductData",
  async () => {
    try {
      const response = await axios.get(`/api/data/product?_=${Math.random()}`);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProductData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getProductData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default productSlice.reducer;
