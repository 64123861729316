import mail from "../../assets/img/mail.png";
import phone from "../../assets/img/phone.png";
import fb from "../../assets/img/facebook.svg";
import ig from "../../assets/img/instagram.svg";
import linkin from "../../assets/img/linkedin.svg";
import twitter from "../../assets/img/twitter.svg";
import wa from "../../assets/img/whatsapp.svg";
import location from "../../assets/img/location.png";
import rocket from "../../assets/img/rocket.gif";
import { useEffect, useState } from "react";
import whitelogo from "../../assets/img/whitelogo.png";

const Footer = (): JSX.Element => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <footer>
        <div className="bg-base">
          <div className="container t-white py-5 sub-footer">
            <div className="row">
              <div className="col-md-4 px-3 fs-7 mb-2">
                <div className="mb-3">
                  <h3 className="fw-bold">DUKODU</h3>
                </div>
                <div className="d-flex">
                  <img
                    src={location}
                    alt="dukodu"
                    width="25"
                    height="25"
                    className="me-2"
                  />
                  <p>
                    Margonda Raya Jl. Salak No 520 Ruko B-C, Kel, Pondok Cina,
                    Kecamatan Beji, Kota Depok, Jawa Barat 16424
                  </p>
                </div>
                <div className="d-flex">
                  <img
                    src={location}
                    alt="dukodu"
                    width="25"
                    height="25"
                    className="me-2"
                  />
                  <p>
                    Jalan Ahmad Yani 1 Komplek Ayani Megamall Ruko No. C 9-10
                    Kelurahan Parit Tokaya, Pontianak Selatan - 78123
                  </p>
                </div>
                <div className="d-flex">
                  <img
                    src={wa}
                    alt="dukodu"
                    width="25"
                    height="25"
                    className="me-2"
                  />
                  <p>+62 811-5634-634 (Chat Only)</p>
                </div>
                <div className="d-flex">
                  <img
                    src={mail}
                    alt="dukodu"
                    width="25"
                    height="25"
                    className="me-2"
                  />
                  <p>cs@dukodu.id</p>
                </div>
              </div>
              <div className="col-md-4 px-3">
                <div className="mb-3">
                  <h3 className="fw-semibold fs-3">Social Media</h3>
                </div>
                <div className="my-2 my-socmed">
                  <div className="d-flex align-items-center">
                    <img
                      src={fb}
                      alt="dukodu"
                      style={{ width: "30px", height: "30px" }}
                      className="me-1 mb-1"
                    />{" "}
                    <a
                      href="https://www.facebook.com/dukoduhome"
                      target="_blank"
                      className="t-white fs-7"
                    >
                      Official Facebook Dukodu
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={twitter}
                      alt="dukodu"
                      style={{ width: "30px", height: "30px" }}
                      className="me-1 mb-1"
                    />
                    <a
                      href="https://twitter/dukodu.id"
                      target="_blank"
                      className="t-white fs-7"
                    >
                      Official Twitter Dukodu
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={ig}
                      alt="dukodu"
                      style={{ width: "30px", height: "30px" }}
                      className="me-1 mb-1"
                    />
                    <a
                      href="https://www.instagram.com/dukodu.id/"
                      target="_blank"
                      className="t-white fs-7"
                    >
                      Official Instagram Dukodu
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={linkin}
                      alt="dukodu"
                      style={{ width: "30px", height: "30px" }}
                      className="me-1 mb-1"
                    />
                    <a
                      href="https://www.linkedin.com/company/dukodu/?originalSubdomain=id"
                      target="_blank"
                      className="t-white fs-7"
                    >
                      Official LinkedIn Dukodu
                    </a>
                  </div>
                  <div className="d-flex align-items-center">
                    <img
                      src={wa}
                      alt="dukodu"
                      style={{ width: "30px", height: "30px" }}
                      className="me-1 mb-1"
                    />
                    <a
                      href="https://wa.me/628115634634?text=Halo,%20Saya%20ingin%20berlangganan%20internet%20Dukodu.%20Mohon%20bantuannya!"
                      target="blank"
                      className="t-white fs-7"
                    >
                      Official Whatsapp Dukodu
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4 px-3 fs-7">
                <div className="mb-3">
                  <h3 className="fw-semibold fs-3">Coverage Area</h3>
                </div>
                <div>DUKODU INTERNET BROADBRAND</div>
                <div>DUKODU HOME</div>
                <div>
                  DEPOK | BOGOR | CIREBON | KOTA BEKASI | KALIMANTAN BARAT
                </div>
                <div className="mt-3">Owned By : </div>
                <div className="fs-5 fw-bold">PT Dukodu Digital Solution</div>
                <div className="mt-3">Subsidiary Of : </div>
                <div>
                  <img src={whitelogo} alt="dukodu" width={200} height={100} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-copyright py-3">
          <div className="d-flex justify-content-center">
            © 2024 Dukodu. All Rights Reserved.
          </div>
          <div className="d-flex justify-content-center text-center">
            Powered by PT Trans Hybrid Communication
          </div>
        </div>
      </footer>
      <a
        href="#"
        id="scroll-top"
        className={`${offset === 0 ? "" : "d-inline-block"}`}
      >
        <img src={rocket} alt="slide-up" width="60" height="60" />
      </a>
    </>
  );
};

export default Footer;
