import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { ServiceState } from "../utils/interfaces";

const initialState: ServiceState = {
  loading: false,
  data: {},
  error: {},
};

const config = {
  headers: { "content-type": "multipart/form-data" },
};

export const getOTP = createAsyncThunk("get/getOTP", async (data: any) => {
  try {
    const response = await axios.get(`/api/sendotpbankva?x=${Math.random()}`, {
      params: data,
    });
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const postRequestOTPCancelStatic = createAsyncThunk(
  "post/postRequestOTPCancelStatic",
  async (data: any) => {
    try {
      const response = await axios.post(
        `/api/staticva/request-cancel`,
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const postValidateOTPCancelStatic = createAsyncThunk(
  "post/postValidateOTPCancelStatic",
  async (data: any) => {
    try {
      const response = await axios.post(
        `/api/staticva/validate-request-cancel`,
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const getInvoiceData = createAsyncThunk(
  "get/getInvoiceData",
  async (data: any) => {
    try {
      const response = await axios.get(`/api/inquiry?x=${Math.random()}`, {
        params: data,
      });
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const getListBankVAStatic = createAsyncThunk(
  "get/getListBankVAStaticData",
  async (data: any) => {
    try {
      const response = await axios.get(`/api/listbankva?x=${Math.random()}`, {
        params: data,
      });
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const getValidateInvoice = createAsyncThunk(
  "get/getValidateInvoice",
  async (data: any) => {
    try {
      const response = await axios.get(`/api/inquiry/invoice/verifysignature`, {
        params: data,
      });
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const postPayInvoice = createAsyncThunk(
  "post/postPayInvoice",
  async (data: any) => {
    try {
      const response = await axios.post(`/api/inquiry/pay`, data, config);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const postUpdateBank = createAsyncThunk(
  "post/postUpdateBank",
  async (data: any) => {
    try {
      const response = await axios.post(`/api/updateva`, data, config);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const postCancelPaymentMethod = createAsyncThunk(
  "post/postCancelPaymentMethod",
  async (data: any) => {
    try {
      const response = await axios.post(
        `/api/inquiry/invoice/cancel`,
        data,
        config
      );
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get otp
      .addCase(getOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOTP.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getOTP.rejected, (state, action) => {
        state.loading = false;
      })

      // request otp cancel va static
      .addCase(postRequestOTPCancelStatic.pending, (state) => {
        state.loading = true;
      })
      .addCase(postRequestOTPCancelStatic.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(postRequestOTPCancelStatic.rejected, (state, action) => {
        state.loading = false;
      })

      // validate otp cancel va static
      .addCase(postValidateOTPCancelStatic.pending, (state) => {
        state.loading = true;
      })
      .addCase(postValidateOTPCancelStatic.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(postValidateOTPCancelStatic.rejected, (state, action) => {
        state.loading = false;
      })

      // get data tagihan
      .addCase(getInvoiceData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInvoiceData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getInvoiceData.rejected, (state, action) => {
        state.loading = false;
      })

      // get list bank va
      .addCase(getListBankVAStatic.pending, (state) => {
        state.loading = true;
      })
      .addCase(getListBankVAStatic.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getListBankVAStatic.rejected, (state, action) => {
        state.loading = false;
      })

      // get validasi tagihan
      .addCase(getValidateInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(getValidateInvoice.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getValidateInvoice.rejected, (state, action) => {
        state.loading = false;
      })

      // bayar tagihan
      .addCase(postPayInvoice.pending, (state) => {
        state.loading = true;
      })
      .addCase(postPayInvoice.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postPayInvoice.rejected, (state, action) => {
        state.loading = false;
      })

      // cancel payment method
      .addCase(postCancelPaymentMethod.pending, (state) => {
        state.loading = true;
      })
      .addCase(postCancelPaymentMethod.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postCancelPaymentMethod.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default invoiceSlice.reducer;
