import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TestimoniState } from "../utils/interfaces";

const initialState: TestimoniState = {
  loading: false,
  data: {},
};

export const getTestimoniData = createAsyncThunk(
  "get/getTestimoniData",
  async () => {
    try {
      const response = await axios.get(
        `/api/data/testimoni?_=${Math.random()}`
      );
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const testimoniSlice = createSlice({
  name: "testimoni",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTestimoniData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getTestimoniData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getTestimoniData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default testimoniSlice.reducer;
