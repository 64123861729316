import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { BlogState } from "../utils/interfaces";

const initialState: BlogState = {
  loading: false,
  data: {},
};

export const getBlogData = createAsyncThunk("get/getBlogData", async () => {
  try {
    const response = await axios.get(`/api/data/blog?x=${Math.random()}`);
    return response.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const getBlogDataById = createAsyncThunk(
  "get/getBlogDataById",
  async (data: any) => {
    try {
      const response = await axios.get(`/api/blog/${data}?x=${Math.random()}`);
      return response.data.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const getBlogLatest = createAsyncThunk("get/getBlogLatest", async () => {
  try {
    const response = await axios.get(
      `/api/data/blog/latest?_=${Math.random()}`
    );
    return response.data.data;
  } catch (error: any) {
    return error.response.data;
  }
});

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get list blog
      .addCase(getBlogData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogData.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getBlogData.rejected, (state) => {
        state.loading = false;
      })

      // get blog by id
      .addCase(getBlogDataById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogDataById.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getBlogDataById.rejected, (state) => {
        state.loading = false;
      })

      // get blog latest
      .addCase(getBlogLatest.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogLatest.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getBlogLatest.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default blogSlice.reducer;
