import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import ThumbnailPage from "../../components/thumbnailPage/thumbnailPage";
import mail from "../../assets/img/mail.png";
import phone from "../../assets/img/phone.png";
import location from "../../assets/img/location.png";
import "./contact.css";
import { useCallback, useEffect, useState } from "react";
import { ContactForm } from "../../utils/types";
import { useAppDispatch } from "../../app/hook";
import { postFormContact } from "../../features/contactSlice";
import { Form, InputGroup, Toast } from "react-bootstrap";

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";

const initialDataForm: ContactForm = {
  name: "",
  email: "",
  whatsapp: "",
  message: "",
};

const Contact = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [formContact, setFormContact] = useState<ContactForm>(initialDataForm);
  const [responseContact, setResponseContact] = useState("");
  const [errFormContact, setErrFormContact] = useState({
    errname: "",
    erremail: "",
    errwhatsapp: "",
    errmessage: "",
  });
  const [toastFormContact, setToastFormContact] = useState(false);
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback((token: any) => {
    setToken(token);
  }, []);

  const handleChangeForm = useCallback(
    (e: any) => {
      const { name, value } = e.target;

      setFormContact({ ...formContact, [name]: value });
    },
    [formContact]
  );

  const submitFormContact = (e: any) => {
    e.preventDefault();

    // validate name
    if (formContact.name === "") {
      setErrFormContact({
        ...errFormContact,
        errname: "Silahkan masukan nama.",
      });
      return;
    } else {
      setErrFormContact({ ...errFormContact, errname: "" });
    }

    // validate email
    if (formContact.email === "") {
      setErrFormContact({
        ...errFormContact,
        erremail: "Silahkan masukan email.",
      });
      return;
    } else {
      setErrFormContact({ ...errFormContact, erremail: "" });
    }

    // validate whatsapp
    if (formContact.whatsapp === "") {
      setErrFormContact({
        ...errFormContact,
        errwhatsapp: "Silahkan masukan whatsapp.",
      });
      return;
    } else if (isNaN(Number(formContact.whatsapp)) === true) {
      setErrFormContact({
        ...errFormContact,
        errwhatsapp: "Silahkan masukan angka.",
      });
      return;
    } else {
      setErrFormContact({ ...errFormContact, errwhatsapp: "" });
    }

    // validate message
    if (formContact.message === "") {
      setErrFormContact({
        ...errFormContact,
        errmessage: "Silahkan masukan pesan.",
      });
      return;
    } else {
      setErrFormContact({ ...errFormContact, errmessage: "" });
    }

    let payload = new FormData();

    payload.append("name", formContact.name);
    payload.append("email", formContact.email);
    payload.append("whatsapp", formContact.whatsapp);
    payload.append("message", formContact.message);
    payload.append("tkndkd", token);

    dispatch(postFormContact(payload))
      .unwrap()
      .then((data) => {
        setResponseContact(data.msg);
        setToastFormContact(true);
      })
      .catch((err) => {
        setResponseContact(err.msg);
        setToastFormContact(true);
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Call your function or perform the desired actions here
      setRefreshReCaptcha((r) => !r);
    }, 2 * 60 * 1000); // 2 minutes in milliseconds

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component is unmounted
    };
  }, []);

  return (
    <>
      <div className="w-100">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dukodu - Contact</title>
        </Helmet>

        <Header page="contact" />

        {/* thumbnail */}
        <ThumbnailPage title="Kontak" />

        {/* content & form */}
        <section className="bg-second">
          <div className="container py-5">
            <div className="row">
              <div className="col-md-4">
                <div className="bg-third p-3 t-white rounded-1">
                  <div className="d-flex">
                    <img
                      src={location}
                      alt="address"
                      width="30"
                      height="30"
                      className="me-2"
                    />
                    <div>
                      <h6>Alamat Kantor</h6>
                      <p className="fs-7">
                        Margonda Raya Jl. Salak No 520 Ruko B-C, Kel, Pondok
                        Cina, Kecamatan Beji, Kota Depok, Jawa Barat 16424{" "}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <img
                      src={phone}
                      alt="address"
                      width="30"
                      height="30"
                      className="me-2"
                    />
                    <div>
                      <h6>Telepon Kami</h6>
                      <p className="fs-7">+62 811-5634-634</p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <img
                      src={mail}
                      alt="address"
                      width="30"
                      height="30"
                      className="me-2"
                    />
                    <div>
                      <h6>Email Kami</h6>
                      <p className="fs-7">cs@dukodu.id </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 contact-form">
                <div className="bg-white p-3 rounded-1">
                  <form action="">
                    <h4 className="fw-semibold">Hubungi Kami</h4>
                    <div className="row">
                      <div className="col-md-6">
                        <InputGroup hasValidation className="mb-3">
                          <Form.Control
                            type="text"
                            value={formContact.name}
                            minLength={1}
                            maxLength={20}
                            required
                            isInvalid={
                              errFormContact.errname === "" ? false : true
                            }
                            name="name"
                            id="name"
                            placeholder="Masukan Namamu..."
                            onChange={(e) => handleChangeForm(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errFormContact.errname}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </div>
                      <div className="col-md-6">
                        <InputGroup hasValidation className="mb-3">
                          <Form.Control
                            type="email"
                            value={formContact.email}
                            minLength={1}
                            maxLength={35}
                            required
                            isInvalid={
                              errFormContact.erremail === "" ? false : true
                            }
                            name="email"
                            id="email"
                            placeholder="Masukan Emailmu"
                            onChange={(e) => handleChangeForm(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errFormContact.erremail}
                          </Form.Control.Feedback>
                        </InputGroup>
                      </div>
                    </div>
                    <InputGroup hasValidation className="mb-3">
                      <Form.Control
                        type="text"
                        value={formContact.whatsapp}
                        minLength={1}
                        maxLength={15}
                        required
                        isInvalid={
                          errFormContact.errwhatsapp === "" ? false : true
                        }
                        name="whatsapp"
                        id="whatsapp"
                        placeholder="Masukan Nomor Whatsapp"
                        onChange={(e) => handleChangeForm(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errFormContact.errwhatsapp}
                      </Form.Control.Feedback>
                    </InputGroup>

                    <InputGroup hasValidation className="mb-3">
                      <Form.Control
                        as={"textarea"}
                        type="text"
                        minLength={1}
                        maxLength={120}
                        cols={30}
                        rows={5}
                        value={formContact.message}
                        required
                        isInvalid={
                          errFormContact.errmessage === "" ? false : true
                        }
                        name="message"
                        id="message"
                        placeholder="Tulis Pesanmu"
                        onChange={(e) => handleChangeForm(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errFormContact.errmessage}
                      </Form.Control.Feedback>
                    </InputGroup>

                    <GoogleReCaptchaProvider reCaptchaKey="6Ldxxw4bAAAAAHVBc271fWbPo5tLPiq1hlslR17a">
                      <GoogleReCaptcha
                        onVerify={onVerify}
                        refreshReCaptcha={refreshReCaptcha}
                      />
                    </GoogleReCaptchaProvider>

                    <button
                      type="submit"
                      className="theme-btn"
                      onClick={(e) => submitFormContact(e)}
                    >
                      Kirim Pesan
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* maps */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.278348211078!2d106.83060865053717!3d-6.358006295376548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f1eb777df4ad%3A0x396d9bf48bd893b9!2sGraha%20Trans%20Hybrid%20dan%20Ekadata!5e0!3m2!1sid!2sid!4v1676357494935!5m2!1sid!2sid"
          width="100%"
          height="450"
          style={{ border: "0" }}
          allowFullScreen={true}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
        <Footer />

        <div className="toast-container position-fixed bottom-0 end-0 p-3">
          <Toast
            onClose={() => setToastFormContact(false)}
            show={toastFormContact}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">DUKODU</strong>
              {/* <small>11 mins ago</small> */}
            </Toast.Header>
            <Toast.Body>{responseContact}</Toast.Body>
          </Toast>
        </div>
      </div>
    </>
  );
};

export default Contact;
