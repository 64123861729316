import { Modal } from "react-bootstrap";
import TVChannel from "./tvChannel";
import { useState } from "react";

const ModalTV = () => {
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <div className="d-flex justify-content-center">
        <button className="button-54" onClick={() => setModalShow(true)}>
          Lihat Channel TV
        </button>
      </div>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            TV Channels
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {TVChannel.map((item) => (
              <div
                className="col-lg-4 d-flex align-items-center mb-2"
                key={item.name}
              >
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "#df0303",
                  }}
                  className="rounded-circle me-2"
                ></div>
                <div
                  className="px-2 rounded"
                  style={{ backgroundColor: "#f1f1f1" }}
                >
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalTV;
