import axios from "../axios/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { UpgradeState } from "../utils/interfaces";

const initialState: UpgradeState = {
  loading: false,
  data: {},
};

export const getPromoPaketData = createAsyncThunk(
  "get/getPromoPaketData",
  async (data: any) => {
    try {
      const response = await axios.get(`/api/promo/confidential/detail`, {
        params: { p: data?.nohp, t: data?.tokenParam, rt: data?.requestTime },
      });

      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const postUpgradeData = createAsyncThunk(
  "post/postUpgradeData",
  async (data: any) => {
    try {
      const response = await axios.post(`/api/promo/confidential/submit`, data);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
);

export const upgradeSlice = createSlice({
  name: "upgrade",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // get data customer by nohp & token
      .addCase(getPromoPaketData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPromoPaketData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(getPromoPaketData.rejected, (state, action) => {
        state.loading = false;
      })

      // post upgrade data
      .addCase(postUpgradeData.pending, (state) => {
        state.loading = true;
      })
      .addCase(postUpgradeData.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postUpgradeData.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default upgradeSlice.reducer;
