import { Helmet } from "react-helmet";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import { Col, Form, Toast } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { useAppDispatch } from "../../app/hook";
import { postOTP, resendOTP } from "../../features/otpSlice";
import { useLocation, useNavigate } from "react-router-dom";

const Verification = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const nohp = queryParams.get("p");
  const tokenParam = queryParams.get("t");

  const [formOTP, setFormOTP] = useState("");
  const [responseOTP, setResponseOTP] = useState("");
  const [toastFormOTP, setToastFormOTP] = useState<boolean>(false);
  const [errFormOTP, setErrFormOTP] = useState("");
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const [disableOTP, setDisableOTP] = useState(false);

  const onVerify = useCallback((token: any) => {
    setToken(token);
  }, []);

  const handleChangeFormOTP = (e: any) => {
    const { value } = e.target;

    // setFormOTP(value);

    if (isNaN(value as any)) {
      e.target.value = "";
    } else {
      setFormOTP(value);
    }

    // validate nilai input
    let errorMessage = "";

    if (value === "") {
      errorMessage = "Kode OTP harus diisi";
      setDisableOTP(true);
    } else if (value.length !== 6) {
      errorMessage = "Kode OTP harus terdiri dari 6 digit angka";
      setDisableOTP(true);
    } else {
      setDisableOTP(false);
    }

    setErrFormOTP(errorMessage);
  };

  const handleResendOTP = (e: any) => {
    e.preventDefault();

    const payload = {
      p: nohp,
      t: tokenParam,
    };

    dispatch(resendOTP(payload))
      .unwrap()
      .then((data) => {
        setResponseOTP(data.msg);
        setToastFormOTP(true);
      })
      .catch((err) => {
        setResponseOTP(err.msg);
        setToastFormOTP(true);
      });
  };

  const submitFormOTP = (e: any) => {
    e.preventDefault();

    let payload = new FormData();

    payload.append("o", formOTP);
    payload.append("p", nohp ? nohp : "");
    payload.append("t", tokenParam ? tokenParam : "");
    payload.append("tkndkd", token);

    dispatch(postOTP(payload))
      .unwrap()
      .then((data) => {
        setResponseOTP(data.msg);
        setToastFormOTP(true);
        if (data.status) {
          setTimeout(() => {
            navigate(`/success-upgrade?t=${tokenParam}`);
          }, 1500);
        }
      })
      .catch((err) => {
        setResponseOTP(err.msg);
        setToastFormOTP(true);
      });
  };

  useEffect(() => {
    if (!nohp || !tokenParam) {
      navigate("/");
    }
    const intervalId = setInterval(() => {
      // Call your function or perform the desired actions here
      setRefreshReCaptcha((r) => !r);
    }, 2 * 60 * 1000); // 2 minutes in milliseconds

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component is unmounted
    };
  }, []);

  useEffect(() => {
    if (formOTP === "") {
      setDisableOTP(true);
    } else if (formOTP.length !== 6) {
      setDisableOTP(true);
    } else {
      setDisableOTP(false);
    }
  }, [formOTP]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Masukan Kode OTP</title>
      </Helmet>

      <Header page="verification" />

      <div className="container my-5" style={{ height: "300px" }}>
        <div className="py-3">
          <div className="text-center">
            <div className="fs-32 fw-bold t-red">Verifikasi Kode OTP</div>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <Form onSubmit={submitFormOTP}>
            <Form.Group as={Col}>
              <Form.Label className="text-center" htmlFor="otp">
                Silahkan masukan kode otp yang telah dikirim ke nomor whatsapp
                anda. <br />
                <a
                  href=""
                  onClick={(e) => {
                    handleResendOTP(e);
                    setFormOTP("");
                  }}
                >
                  Kirim ulang kode OTP
                </a>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Masukan Kode OTP"
                className="mb-3"
                maxLength={6}
                minLength={6}
                isInvalid={errFormOTP === "" ? false : true}
                isValid={errFormOTP === "" && formOTP !== "" ? true : false}
                onChange={(e) => handleChangeFormOTP(e)}
                name="otp"
                required
                value={formOTP}
                id="otp"
              />
              <Form.Control.Feedback type="invalid">
                {errFormOTP}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </div>

        <div className="py-3">
          <div className="d-flex justify-content-center">
            <button
              className={disableOTP ? "theme-btn-disabled" : "theme-btn"}
              onClick={(e) => submitFormOTP(e)}
              disabled={disableOTP}
            >
              Submit
            </button>
          </div>
        </div>
        <GoogleReCaptchaProvider reCaptchaKey="6Ldxxw4bAAAAAHVBc271fWbPo5tLPiq1hlslR17a">
          <GoogleReCaptcha
            onVerify={onVerify}
            refreshReCaptcha={refreshReCaptcha}
          />
        </GoogleReCaptchaProvider>
      </div>
      <Footer />

      <div className="toast-container position-fixed bottom-0 end-0 p-3">
        <Toast
          onClose={() => setToastFormOTP(false)}
          show={toastFormOTP}
          delay={1500}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">DUKODU</strong>
          </Toast.Header>
          <Toast.Body>{responseOTP}</Toast.Body>
        </Toast>
      </div>
    </>
  );
};

export default Verification;
