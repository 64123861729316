import { Helmet } from "react-helmet";
import Header from "../../components/header/header";
import ThumbnailPage from "../../components/thumbnailPage/thumbnailPage";
import Faq from "../../components/faq/faq";
import Footer from "../../components/footer/footer";

const FAQ = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dukodu - FAQ</title>
      </Helmet>

      <Header page="faq" />

      {/* thumbnail */}
      <ThumbnailPage title="Frequently Asked Question" />

      {/* formal information */}
      <section>
        <div className="container pt-5">
          <h4 className="fw-semibold">DUKODU</h4>
          <p>
            Dukodu adalah salah satu produk layanan internet dari PT Dukodu
            Digital Solution, anak perusahaan dari PT Trans Hybrid
            Communication. Dengan Tagline “DUsun KOta DUnia. Memberi makna bahwa
            DUKODU hadir untuk membuka informasi dari dusun ke kota dan dunia,
            dan dukodu juga membawa informasi dari dunia dan kota ke dusun.
            Layanan internet dukodu menggunakan technology Fiber Optic sampai ke
            lokasi akhir pelanggan seperti Perumahan, Desa- Desa, Ruko-Ruko di
            pusat perbelanjaan dengan bandwidth internet Broadband tanpa batas
            kuota ( Unlimited Download ).
          </p>
        </div>
      </section>

      <Faq />

      <Footer />
    </>
  );
};

export default FAQ;
